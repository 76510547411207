import { Container, HStack, Heading, VStack } from "@chakra-ui/react";
import JobPreview from "../components/JobPreview";
import { useCompany } from "../providers/CompanyProvider";
import { useJobs } from "../providers/JobsProvider";
import LoadingScreen from "../components/LoadingScreen";

export default function HomePage() {
	const { company } = useCompany();
	const { atsJobs, loadingJobs } = useJobs();

	const sortedJobs = atsJobs?.filter(
		(job) => job.sorters && job.sorters?.length > 0
	);
	const openJobs = atsJobs?.filter(
		(job) => job.status === "OPEN" && !sortedJobs?.includes(job)
	);
	const draftedJobs = atsJobs?.filter(
		(job) => job.status === "DRAFT" && !sortedJobs?.includes(job)
	);
	const pendingJobs = atsJobs?.filter(
		(job) => job.status === "PENDING" && !sortedJobs?.includes(job)
	);

	// Create a combined array of open, drafted, and pending jobs
	const unsortedJobs = [
		...(openJobs || []),
		...(draftedJobs || []),
		...(pendingJobs || []),
	];

	if (loadingJobs) {
		return <LoadingScreen text="Loading jobs..." />;
	} else
		return (
			<VStack>
				<Container maxW="4xl">
					<HStack justify="space-between" pb={8}>
						<Heading size="3xl">Jobs at {company?.name}</Heading>
					</HStack>

					{sortedJobs?.length && sortedJobs?.length > 0 ? (
						<VStack
							align="flex-start"
							p={4}
							gap={8}
							w="full"
							borderColor="primary40"
							borderWidth={4}
							borderRadius="lg">
							<VStack align="flex-start" w="full">
								<Heading size="lg" color="primary">
									Sorted
								</Heading>
								{sortedJobs?.map((job) => (
									<JobPreview key={job.id} job={job} />
								))}
							</VStack>
						</VStack>
					) : null}

					{unsortedJobs.length > 0 ? (
						<VStack
							align="flex-start"
							mt={8}
							p={4}
							gap={1}
							w="full"
							borderColor="dark5"
							borderWidth={4}
							borderRadius="lg">
							<Heading size="lg" color="dark60" pb={4}>
								Ready to sort
							</Heading>

							{unsortedJobs.map((job) => (
								<JobPreview key={job.id} job={job} />
							))}
						</VStack>
					) : null}
				</Container>
			</VStack>
		);
}
