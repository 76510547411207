import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { JobSVC } from "../services/JobServices";
import {
	Container,
	Heading,
	VStack,
	Link,
	Image,
	Text,
	HStack,
	Box,
	Button,
	Tag,
	Divider,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	useDisclosure,
	Collapse,
	Wrap,
} from "@chakra-ui/react";
import { JobIcon } from "../assets/icons/JobIcon";
import candidateIocn from "../assets/candidate.png";
import { MoreIcon } from "../assets/icons/MoreIcon";
import DeleteJobWarningModal from "../components/DeleteJobWarningModal";
import { useJobs } from "../providers/JobsProvider";
import LoadingScreen from "../components/LoadingScreen";
import { useCompany } from "../providers/CompanyProvider";
import { SortingQueueTracker } from "../components/SortingQueueTracker";
import JobCandidateList from "../components/JobCandidateList";

export default function JobPage() {
	const navigate = useNavigate();
	const { id } = useParams();
	const { isOpen, onToggle } = useDisclosure();
	const { setJobById, currentJob: job } = useJobs();
	const { company } = useCompany();
	const [warningModalOpen, setWarningModalOpen] = useState<boolean>(false);
	const [isDeleting, setIsDeleting] = useState<boolean>(false);
	const [showSortingQueue, setShowSortingQueue] = useState<boolean>(true);

	// get job by id
	useEffect(() => {
		if (!id || job) return;
		setJobById(id);
	}, [id, job, setJobById]);

	// Show sorting queue tracker for 10 seconds
	useEffect(() => {
		const timer = setTimeout(() => {
			setShowSortingQueue(false);
		}, 10000);

		return () => clearTimeout(timer);
	}, []);

	// get company name

	const giveStatusTag = () => {
		if (job?.status) {
			return <Tag>{job?.status}</Tag>;
		} else {
			return <Tag variant="gray">Archived</Tag>;
		}
	};

	const handleDelete = async () => {
		setIsDeleting(true);
		if (job && job.id) {
			const error = await JobSVC.deleteOne(job.id);
			if (error) {
				console.error("Error deleting job: ", error);
			} else {
				navigate("/home");
			}
		}
	};

	if (!job || !job.id) return <LoadingScreen text="Loading job details..." />;
	return (
		<>
			<VStack pb={40}>
				<Container maxW="4xl">
					<VStack align="flex-start" spacing={8}>
						<VStack align="flex-start" spacing={3} w="full">
							<Link
								onClick={() => navigate("/home")}
								color="primary"
								fontWeight="bold">
								← Go back
							</Link>
							<HStack justify="space-between" w="full">
								<HStack>
									<JobIcon color="primary" heightInRem={5.5} />
									<VStack align="flex-start">
										<Heading size="2xl">{job.name}</Heading>
										<HStack>
											{giveStatusTag()}
											<Text size="2xl" color="dark60">
												{company?.name ? ` ${company.name}` : ""}
											</Text>
										</HStack>
									</VStack>
								</HStack>
								<Menu placement="bottom-end">
									<MenuButton
										as={IconButton}
										variant="icon"
										icon={<MoreIcon heightInRem={2} />}
										aria-label="Menu"
									/>
									<MenuList>
										<MenuItem>Edit</MenuItem>
										<Divider />
										<MenuItem
											color="red"
											onClick={() => setWarningModalOpen(true)}
											_hover={{
												bg: "red20",
											}}>
											Delete
										</MenuItem>
									</MenuList>
								</Menu>
							</HStack>
							<VStack p={2} bg="white" align="left" w="full" borderRadius="lg">
								<Collapse in={isOpen} animateOpacity>
									<HStack p={4}>
										<Text color="dark60" fontWeight="bold">
											{job.addresses &&
												job.addresses.length > 0 &&
												`in ${job.addresses[0]?.city}, ${job.addresses[0]?.region}`}
										</Text>
									</HStack>
									<Box p={4}>
										<Heading size="md">Role Details</Heading>
										<Text color="dark60">
											{job.sorters && job.sorters[0].role_details}
										</Text>
									</Box>
									<Box p={4}>
										<Heading size="md">Sort Criteria</Heading>
										<Text color="dark60">
											{job.sorters && job.sorters[0].sort_criteria}
										</Text>
									</Box>
									<Box p={4}>
										<Heading size="md">Related Titles</Heading>
										<Wrap spacing={1} align="center" py={2}>
											{job.sorters &&
												job.sorters[0].related_titles &&
												job.sorters[0].related_titles.map((title) => (
													<Tag key={title} variant="gray">
														{title}
													</Tag>
												))}
										</Wrap>
									</Box>
								</Collapse>
								<Button onClick={onToggle} variant="text">
									{isOpen ? "Hide" : "Show"} job sorting details
								</Button>
							</VStack>
						</VStack>

						<VStack
							borderRadius="lg"
							bg="white"
							p={4}
							w="full"
							align="flex-start">
							<HStack justify="space-between" w="full" mb={4}>
								<HStack gap={4}>
									<Image src={candidateIocn} alt="job description" w={12} />
									<Box>
										<Heading size="lg">Candidates</Heading>
										<Text color="dark60">
											Upload the resumes for all your candidates for the job.
										</Text>
									</Box>
								</HStack>
							</HStack>
							{showSortingQueue ? (
								<SortingQueueTracker jobId={job?.id} />
							) : (
								<JobCandidateList jobId={job?.id} />
							)}
						</VStack>
					</VStack>
				</Container>
			</VStack>

			<DeleteJobWarningModal
				isOpen={warningModalOpen}
				onClose={() => setWarningModalOpen(false)}
				onConfirm={() => handleDelete()}
				loading={isDeleting}
			/>
		</>
	);
}
