import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import AppRouter from "./router";
import { theme } from "./theme";
import { AuthSessionProvider } from "./providers/AuthProvider";
import { ResumeProcessingProvider } from "./providers/ResumeProcessingProvider";
import { CompanyProvider } from "./providers/CompanyProvider";
import { JobsProvider } from "./providers/JobsProvider";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<React.StrictMode>
		<ChakraProvider theme={theme}>
			<AuthSessionProvider>
				<CompanyProvider>
					<JobsProvider>
						<ResumeProcessingProvider>
							<AppRouter />
						</ResumeProcessingProvider>
					</JobsProvider>
				</CompanyProvider>
			</AuthSessionProvider>
		</ChakraProvider>
	</React.StrictMode>
);
