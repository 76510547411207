import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Auth from "./pages/AuthPage";
import App from "./App";
import UploadCandidatesPage from "./pages/UploadCandidatePage";
import JobPage from "./pages/JobPage";
import AccountPage from "./pages/AccountPage";
import SettingsPage from "./pages/SettingsPage";
import AddCompanyPage from "./pages/AddCompanyPage";
import SelectATSPage from "./pages/atsIntegration/SelectATSPage";
import ConnectionFailedPage from "./pages/atsIntegration/ConnectionFailedPage";
import SyncJobsPage from "./pages/atsIntegration/SyncJobsPage";
import EditSorterDetailsPage from "./pages/addSorterPages/EditSorterDetailsPage";
import GenerateSorterPage from "./pages/addSorterPages/GenerateSorterPage";
import ConfigureSourcingPage from "./pages/addSorterPages/ConfigureSourcingPage";
import ConfigureScreeningPage from "./pages/atsIntegration/ConfigureScreeningPage";
import SorterCreationFlowLayout from "./pages/SorterCreationLayout";

export default function AppRouter() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/auth/:authState" element={<Auth />} />
				<Route path="/add-company" element={<AddCompanyPage />} />
				<Route path="/integrate-ats" element={<SelectATSPage />} />
				<Route path="/connection-failed" element={<ConnectionFailedPage />} />
				<Route path="/sync-jobs" element={<SyncJobsPage />} />
				<Route
					path="/configure-screening"
					element={<ConfigureScreeningPage />}
				/>
				<Route path="/" element={<App />}>
					<Route path="/home" element={<HomePage />} />
					<Route
						path="/add-sorter/:jobId"
						element={<SorterCreationFlowLayout />}>
						<Route index element={<GenerateSorterPage />} />
						<Route
							path="edit-details/:sorterId"
							element={<EditSorterDetailsPage />}
						/>
						<Route
							path="configure-sourcing"
							element={<ConfigureSourcingPage />}
						/>
					</Route>

					<Route path="/job/:id" element={<JobPage />} />

					<Route
						path="/upload-candidates/:jobId"
						element={<UploadCandidatesPage />}
					/>

					<Route path="/account" element={<AccountPage />} />
					<Route path="/settings" element={<SettingsPage />} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
}
