import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	HStack,
	Button,
	Text,
} from "@chakra-ui/react";

interface DeleteJobWarningModalProps {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
	loading: boolean;
}

function DeleteJobWarningModal({
	isOpen,
	onClose,
	onConfirm,
	loading,
}: DeleteJobWarningModalProps) {
	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Delete Job</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text>
						Are you sure you want to delete this job? This action is
						irreversible.
					</Text>
				</ModalBody>
				<HStack justify="flex-end" p={4}>
					<Button onClick={onClose} variant="gray">
						Cancel
					</Button>
					<Button variant="red" onClick={onConfirm} isLoading={loading}>
						Delete
					</Button>
				</HStack>
			</ModalContent>
		</Modal>
	);
}

export default DeleteJobWarningModal;
