import {
	Box,
	Button,
	Divider,
	Flex,
	HStack,
	IconButton,
	Image,
	Link,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Progress,
} from "@chakra-ui/react";
import logo from "../assets/wordmark.png";
import { MenuIcon } from "../assets/icons/MenuIcon";
import { useNavigate } from "react-router";
import { supabase } from "../supabase";
import { useResumeProcessing } from "../providers/ResumeProcessingProvider";
import { useCompany } from "../providers/CompanyProvider";
import { DropdownIcon } from "../assets/icons/DropdownIcon";

function Nav() {
	const navigate = useNavigate();
	const { company, userCompanies, switchCompany } = useCompany();
	const { filesToProcess } = useResumeProcessing();

	const handleLogout = async () => {
		let { error } = await supabase.auth.signOut();
		if (error) console.log("Error logging out:", error.message);
		navigate("/");
	};

	return (
		<>
			<Flex
				direction="column"
				borderBottom={"1px solid #D7D7D7"}
				width={"full"}
				bg="white"
				backdropFilter={"blur(20px)"}
				position="fixed"
				zIndex="overlay">
				{filesToProcess.length > 0 ? (
					<Progress size="md" isIndeterminate bg="primary80" />
				) : (
					<Box h={3} w="full" bg="primary" />
				)}

				<HStack
					px={{ base: 4, lg: 12 }}
					py={{ base: 4, lg: 8 }}
					justify="space-between">
					<Link onClick={() => navigate("home")}>
						<Image src={logo} alt="logo" width={180} />
					</Link>

					<HStack>
						{/* Company Menu */}
						<Menu placement="bottom-end">
							<MenuButton
								as={Button}
								variant="company"
								rightIcon={<DropdownIcon heightInRem={1.5} color="dark60" />}>
								{company?.name}
							</MenuButton>
							<MenuList>
								{userCompanies?.map((company) => (
									<MenuItem
										key={company.id}
										onClick={() => switchCompany(company.id)}>
										{company.name}
									</MenuItem>
								))}
								<Divider />
								<MenuItem onClick={() => navigate("add-company")}>
									+ Add Company
								</MenuItem>
							</MenuList>
						</Menu>
						<Menu>
							<MenuButton
								as={IconButton}
								variant="icon"
								icon={<MenuIcon heightInRem={2} />}
								aria-label="Menu"
							/>
							<MenuList>
								<MenuItem onClick={() => navigate("home")}>Home</MenuItem>
								<MenuItem onClick={() => navigate("account")}>Account</MenuItem>
								<MenuItem onClick={() => navigate("settings")}>
									Settings
								</MenuItem>
								<Divider />
								<MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
							</MenuList>
						</Menu>
					</HStack>
				</HStack>
			</Flex>
			<Box height={44} />
		</>
	);
}

export default Nav;
