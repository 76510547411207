import React, { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useJobs } from "../providers/JobsProvider";
import { VStack, Container } from "@chakra-ui/react";

const SorterCreationFlowLayout: React.FC = () => {
	const { jobId } = useParams();
	const { setJobById } = useJobs();

	useEffect(() => {
		if (jobId) {
			setJobById(jobId);
		}
	}, [jobId, setJobById]);

	return (
		<VStack>
			<Container maxW="4xl">
				<Outlet />
			</Container>
		</VStack>
	);
};

export default SorterCreationFlowLayout;
