import { supabase } from "../supabase";
import { CompanyType } from "../types/CompanyType";

const getOne = async (id: string) => {
	const { data, error } = await supabase
		.from("companies")
		.select("*")
		.eq("id", id);
	if (error) {
		console.error("Error fetching company:", error);
		return null;
	}
	return data[0] as CompanyType;
};

const getByUser = async () => {
	const {
		data: { user },
	} = await supabase.auth.getUser();

	if (!user) return undefined;

	const { data, error } = await supabase
		.from("companies")
		.select()
		.filter("user_ids", "cs", `{${user.id}}`);
	if (error) {
		console.error("Error fetching companies:", error);
		return undefined;
	}

	return data as CompanyType[];
};

const addOne = async (company: Partial<CompanyType>) => {
	const {
		data: { user },
	} = await supabase.auth.getUser();
	if (!user) return null;
	company.user_ids = [user.id];
	const { data } = await supabase.from("companies").insert([company]).select();
	if (!data) return null;
	return data[0];
};

const updateOne = async (company: CompanyType) => {
	const { data, error } = await supabase
		.from("companies")
		.update(company)
		.eq("id", company.id);
	return data ? data[0] : error;
};

const deleteOne = async (id: string) => {
	const { data, error } = await supabase
		.from("companies")
		.delete()
		.eq("id", id);
	return data ? data[0] : error;
};

export const CompanySVC = {
	getOne,
	getByUser,
	addOne,
	updateOne,
	deleteOne,
};
