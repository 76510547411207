import { ComponentStyleConfig } from "@chakra-ui/react";

export const InputStyles: ComponentStyleConfig = {
	baseStyle: {
		field: {
			_focus: {
				borderColor: "primary",
			},
		},
	},
	sizes: {},
	variants: {
		outline: {
			field: {
				width: "full",
				border: "2px solid",
				borderColor: "blueGray",
				borderRadius: "lg",
				_hover: {
					borderColor: "primary60",
				},
				_focusVisible: {
					borderColor: "primary",
					boxShadow: "0 0 0 1px #0092FB",
				},
			},
		},
	},
	defaultProps: {
		variant: "outline",
	},
};
