import { useEffect, useState } from "react";
import { supabase } from "../supabase";
import { ScoredCandidateType } from "../types/CandidateType";
import { CandidateSVC } from "../services/CandidateServices";
import { HStack, VStack, Heading } from "@chakra-ui/react";
import CandidatePreview from "./CandidatePreview";

interface JobCandidateListProps {
	jobId: string;
}

const JobCandidateList: React.FC<JobCandidateListProps> = ({ jobId }) => {
	const [candidates, setCandidates] = useState<ScoredCandidateType[]>([]);

	useEffect(() => {
		const fetchCandidates = async () => {
			const scoredCandidates = await CandidateSVC.getScoredCandidates(jobId);
			// Sort candidates by score in descending order
			scoredCandidates.sort(
				(a, b) => (b.scores?.[0]?.score || 0) - (a.scores?.[0]?.score || 0)
			);
			setCandidates(scoredCandidates);
		};

		fetchCandidates();

		const subscription = supabase
			.channel(`scores-for-job-${jobId}`)
			.on(
				"postgres_changes",
				{
					event: "*",
					schema: "public",
					table: "scores",
					filter: `job_id=eq.${jobId}`,
				},
				() => {
					fetchCandidates();
				}
			)
			.subscribe();

		return () => {
			subscription.unsubscribe();
		};
	}, [jobId]);

	// if (candidates.length === 0) {
	// 	return (
	// 		<HStack
	// 			borderRadius="lg"
	// 			border="4px solid"
	// 			borderColor="dark5"
	// 			color="dark60"
	// 			spacing={4}
	// 			p={4}
	// 			w="full"
	// 			justify="center"
	// 			textAlign="center">
	// 			<Heading size="md">waiting for applications ...</Heading>
	// 		</HStack>
	// 	);
	// }

	return (
		<VStack spacing={2} align="stretch" w="full">
			{candidates.map((candidate) => (
				<CandidatePreview key={candidate.id} candidate={candidate} />
			))}
		</VStack>
	);
};

export default JobCandidateList;
