import {
	Container,
	Link,
	VStack,
	Heading,
	Text,
	FormControl,
	FormLabel,
	Input,
	HStack,
	Button,
	Image,
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router";
import { CompanySVC } from "../services/CompanyServices";
import logo from "../assets/wordmark.png";

export default function AddCompanyPage() {
	const navigate = useNavigate();
	const [companyName, setCompanyName] = useState<string>("");
	const [error, setError] = useState<string>("");

	const handleCreate = async () => {
		// save draft
		if (!companyName) {
			setError("Company name is required");
			return;
		} else {
			const company = {
				name: companyName,
			};
			const newCompany = await CompanySVC.addOne(company);
			console.log(newCompany);
			navigate("/integrate-ats");
		}
	};
	return (
		<VStack spacing={8} mt={20}>
			<Container maxW="3xl">
				<VStack align="flex-start" spacing={8}>
					<VStack align="flex-start" spacing={8}>
						<Image src={logo} alt="logo" width={180} />
						<Heading as="h1" size="2xl">
							First, let's set up your company.
						</Heading>
					</VStack>
					<VStack
						align="flex-start"
						borderRadius="lg"
						spacing={4}
						bg="white"
						p={4}
						w="full">
						<FormControl>
							<FormLabel>Company Name</FormLabel>
							<Input
								placeholder="Type company name"
								value={companyName}
								onChange={(e) => setCompanyName(e.target.value)}
							/>
						</FormControl>
						<HStack
							w="full"
							pt={4}
							justify="space-between"
							borderTop="2px solid"
							borderColor="light">
							<Text color="red">{error}</Text>
							<Button onClick={handleCreate}>Create company</Button>
						</HStack>
					</VStack>
				</VStack>
			</Container>
		</VStack>
	);
}
