import {
	createContext,
	useContext,
	useState,
	useEffect,
	ReactNode,
} from "react";
import { ResumeSVC } from "../services/ResumeServices";

export type Resume = {
	id: string;
	job_id: string;
	resume_key: string;
	completed: boolean;
	errored: boolean;
	error_msg?: string;
};

type ResumeProcessingContextType = {
	filesToProcess: Resume[];
	queueResumes: (resumes: Resume[]) => void;
	processedCount: number;
};

const ResumeProcessingContext = createContext<
	ResumeProcessingContextType | undefined
>(undefined);

export const ResumeProcessingProvider = ({
	children,
}: {
	children: ReactNode;
}) => {
	const [filesToProcess, setFilesToProcess] = useState<Resume[]>(() => {
		// Load initial state from local storage
		const stored = sessionStorage.getItem("filesToProcess");
		return stored ? JSON.parse(stored) : [];
	});
	const [isProcessing, setIsProcessing] = useState<boolean>(() => {
		const stored = sessionStorage.getItem("isProcessing");
		return stored ? JSON.parse(stored) : false;
	});
	const [processedCount, setProcessedCount] = useState<number>(0);

	const queueResumes = (resumes: Resume[]) => {
		setFilesToProcess((prev) => [...prev, ...resumes]);
	};

	useEffect(() => {
		sessionStorage.setItem("isProcessing", JSON.stringify(isProcessing));
	}, [isProcessing]);

	useEffect(() => {
		// Save to local storage whenever filesToProcess changes
		sessionStorage.setItem("filesToProcess", JSON.stringify(filesToProcess));
	}, [filesToProcess]);

	useEffect(() => {
		const processFiles = async () => {
			if (filesToProcess.length > 0 && !isProcessing) {
				setIsProcessing(true);
				const files = filesToProcess.slice(0, 10);
				console.log("Processing files", files);

				for (const file of files) {
					try {
						const res = await ResumeSVC.process(file);
						console.log("✓ Resume processed: ", res);
					} catch (error: any) {
						console.error("Error processing resume: ", error);
						await ResumeSVC.update({
							...file,
							errored: true,
							error_msg: error.message,
						});
					} finally {
						setProcessedCount((prev) => prev + 1);
					}
				}

				// Remove successfully processed files from the queue
				setFilesToProcess((prev) =>
					prev.filter((f) => !files.some((pf) => pf.id === f.id && !f.errored))
				);
				setIsProcessing(false);
			}
		};

		processFiles();
	}, [filesToProcess, isProcessing]);

	return (
		<ResumeProcessingContext.Provider
			value={{ filesToProcess, queueResumes, processedCount }}>
			{children}
		</ResumeProcessingContext.Provider>
	);
};

export const useResumeProcessing = () => {
	const context = useContext(ResumeProcessingContext);
	if (!context) {
		throw new Error(
			"useResumeProcessing must be used within a ResumeProcessingProvider"
		);
	}
	return context;
};
