import { useEffect, useState } from "react";
import { supabase } from "../supabase";
import { HStack, Box, Heading, Text, Spinner } from "@chakra-ui/react";
import { SortingQueueType } from "../types/SortingQueueType";

interface SortingQueueTrackerProps {
	jobId: string;
}

export const SortingQueueTracker: React.FC<SortingQueueTrackerProps> = ({
	jobId,
}) => {
	const [queueCount, setQueueCount] = useState<number>(3);

	// useEffect(() => {
	// 	const fetchInitialCount = async () => {
	// 		const { count } = await supabase
	// 			.from("sorting_queue")
	// 			.select("*", { count: "exact", head: true })
	// 			.eq("job_id", jobId)
	// 			.is("completed", false);

	// 		setQueueCount(count || 0);
	// 	};

	// 	fetchInitialCount();

	// 	const subscription = supabase
	// 		.channel(`sorting-queue-${jobId}`)
	// 		.on(
	// 			"postgres_changes",
	// 			{
	// 				event: "*",
	// 				schema: "public",
	// 				table: "sorting_queue",
	// 				filter: `job_id=eq.${jobId}`,
	// 			},
	// 			(payload) => {
	// 				if (
	// 					payload.eventType === "INSERT" &&
	// 					!(payload.new as SortingQueueType).completed
	// 				) {
	// 					setQueueCount((prev) => prev + 1);
	// 				} else if (payload.eventType === "UPDATE") {
	// 					const oldRecord = payload.old as SortingQueueType;
	// 					const newRecord = payload.new as SortingQueueType;
	// 					if (!oldRecord.completed && newRecord.completed) {
	// 						setQueueCount((prev) => Math.max(0, prev - 1));
	// 					} else if (oldRecord.completed && !newRecord.completed) {
	// 						setQueueCount((prev) => prev + 1);
	// 					}
	// 				}
	// 			}
	// 		)
	// 		.subscribe();

	// 	return () => {
	// 		subscription.unsubscribe();
	// 	};
	// }, [jobId]);

	if (queueCount === 0) return null;

	return (
		<HStack
			borderRadius="lg"
			border="3px solid"
			borderColor="primary"
			spacing={4}
			bg="primary20"
			color="deepBlue"
			p={4}
			w="full">
			<Box px={2}>
				<Spinner color="primary" size="lg" thickness="4px" />
			</Box>
			<Box>
				<Heading size="md">
					Sorting candidate{queueCount > 1 && "s"} ...
				</Heading>
				<Text>
					<b>{queueCount}</b> candidate{queueCount > 1 && "s"} in queue. Please
					wait...
				</Text>
			</Box>
		</HStack>
	);
};
