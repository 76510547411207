import {
	VStack,
	Heading,
	Link,
	Image,
	HStack,
	Box,
	Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import jobDescriptionIcon from "../../assets/job-description.png";
import UploadJobDescription from "../../components/UploadJobDescription";
import { useJobs } from "../../providers/JobsProvider";

export default function GenerateSorterPage() {
	const navigate = useNavigate();
	const { currentJob: job } = useJobs();

	return (
		<VStack align="flex-start" spacing={8}>
			<VStack align="flex-start">
				<Link
					onClick={() => navigate(-1)}
					color="primary"
					fontWeight="bold"
					pb={4}>
					← Go back
				</Link>
				<Heading size="2xl">First, tell us about the job.</Heading>
			</VStack>
			<VStack
				borderRadius="lg"
				spacing={4}
				bg="white"
				p={4}
				w="full"
				align="flex-start">
				<HStack gap={4}>
					<Image src={jobDescriptionIcon} alt="job description" w={12} />
					<Box>
						<Heading size="lg">{job?.name}</Heading>
						<Text color="dark60">
							The AI will use this context to generate a sorting criteria.
						</Text>
					</Box>
				</HStack>
				{job && <UploadJobDescription job={job} />}
			</VStack>
		</VStack>
	);
}
