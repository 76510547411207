import { Button, Container, HStack, Heading, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router";

export default function SettingsPage() {
	const navigate = useNavigate();
	return (
		<VStack>
			<Container maxW="4xl">
				<VStack align="flex-start" spacing={8}>
					<HStack justify="space-between" w="full">
						<Heading size="2xl">Settings</Heading>
					</HStack>
					<VStack
						borderRadius="lg"
						bg="white"
						p={4}
						w="full"
						align="flex-start">
						<Button onClick={() => navigate("/configure-screening")}>
							Congifigure auto screening
						</Button>
					</VStack>
				</VStack>
			</Container>
		</VStack>
	);
}
