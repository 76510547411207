import { CloseButton, HStack, Heading } from "@chakra-ui/react";
import { CompanyType } from "../types/CompanyType";
import { CompanySVC } from "../services/CompanyServices";

function CompanyPreview({ company }: { company: CompanyType }) {
	const handleDelete = async () => {
		// delete company
		await CompanySVC.deleteOne(company.id);
	};

	return (
		<HStack
			justify="space-between"
			w="full"
			p={3}
			border="2px solid"
			borderRadius="lg"
			borderColor="primary20"
			color="primary">
			<Heading size="md">{company.name}</Heading>
			<CloseButton onClick={handleDelete} />
		</HStack>
	);
}

export default CompanyPreview;
