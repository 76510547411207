import { Box, Heading, VStack, Image, Container } from "@chakra-ui/react";
import UnifiedDirectory from "@unified-api/react-directory";
import logo from "../../assets/wordmark.png";

export default function SelectATSPage() {
	const companyID = "1234";
	return (
		<VStack py={20} gap={20}>
			<Container maxW="3xl">
				<VStack spacing={8} align="stretch" pb={12}>
					<Image src={logo} alt="logo" width={180} />
					<Heading as="h1" size="2xl">
						Select your ATS
					</Heading>
				</VStack>
				<VStack>
					<UnifiedDirectory
						workspace_id={"667c23ea2e0602f01315078b"}
						environment={"Sandbox"}
						state={companyID}
						success_redirect="https://www.talentsort.ai/api/ats/connect-ats"
						failure_redirect="https://app.talentsort.ai/configure-screening" // TODO: change this
						nocategories={true}
						notabs={true}
					/>
				</VStack>
			</Container>
		</VStack>
	);
}
