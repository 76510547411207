import { createContext, useContext, useEffect, useState } from "react";
import { AtsJobType } from "../types/AtsJobType";
import { JobSVC } from "../services/JobServices";
import { useCompany } from "./CompanyProvider";

type JobsContextType = {
	atsJobs: AtsJobType[] | undefined;
	loadingJobs: boolean;
	refetchJobs: () => void;
	initiateSync: () => Promise<any> | null;
	getOneJob: (id: string) => AtsJobType | undefined;
	currentJob: AtsJobType | null;
	setJobById: (id: string) => void;
};

export const JobsContext = createContext<JobsContextType>({
	atsJobs: undefined,
	loadingJobs: false,
	refetchJobs: () => null,
	initiateSync: () => null,
	getOneJob: () => undefined,
	currentJob: null,
	setJobById: () => null,
});

export const JobsProvider = ({ children }: { children: React.ReactNode }) => {
	/** ––– How this works –––
	 * - user logs in (from user provider)
	 * - user selects a company to work from
	 * -
	 */

	const { company } = useCompany();

	const [atsJobs, setAtsJobs] = useState<AtsJobType[]>([]);
	const [loading, setLoading] = useState(false);
	const [hasFetchedJobs, setHasFetchedJobs] = useState(false);
	const [currentJob, setCurrentJob] = useState<AtsJobType | null>(null);

	useEffect(() => {
		const fetchJobs = async () => {
			setLoading(true);
			if (!company) return;
			const res = await JobSVC.getAll(company?.id);
			if (!res) return;
			setAtsJobs(res);
			setHasFetchedJobs(true);
			setLoading(false);
			console.log(res);
		};
		if (!hasFetchedJobs) fetchJobs();
	}, [hasFetchedJobs, company]);

	// Add the DEMOcreateJob logic here
	useEffect(() => {
		if (atsJobs.length === 0 && hasFetchedJobs) {
			(async () => {
				if (!company) return;
				const res = await JobSVC.create({
					company_id: company.id,
					created_at: new Date(),
					updated_at: new Date(),
					name: "Senior Software Engineer",
					description:
						"Responsible for developing, testing, and maintaining software applications.",
					status: "OPEN",
					employment_type: "FULL_TIME",
					departments: ["Engineering"],
				});
				console.log("DEMO create job response: ", res);
				setAtsJobs([res]);
			})();
		}
	}, [atsJobs, hasFetchedJobs, company]);

	const initiateSync = async () => {
		// initiate sync
		if (!company) return;
		const res = await JobSVC.syncFromAts(company?.id);
		console.log("Sync response: ", res);
		return res;
	};

	const getOneJob = (id: string) => {
		return atsJobs.find((job) => job.id === id);
	};

	const refetchJobs = () => {
		setHasFetchedJobs(false);
	};

	const setJobById = (id: string) => {
		const job = atsJobs.find((job) => job.id === id);
		if (job) {
			setCurrentJob(job);
		}
	};

	return (
		<JobsContext.Provider
			value={{
				atsJobs,
				loadingJobs: loading,
				refetchJobs,
				initiateSync,
				getOneJob,
				currentJob,
				setJobById,
			}}>
			{children}
		</JobsContext.Provider>
	);
};

export const useJobs = () => useContext(JobsContext);
