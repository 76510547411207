import { extendTheme } from "@chakra-ui/react";
import { HeadingStyles as Heading } from "./styles/HeadingStyles";
import { ButtonStyles as Button } from "./styles/ButtonStyles";
import { InputStyles as Input } from "./styles/InputStyles";
import { TextareaStyles as Textarea } from "./styles/TextAreaStyles";
import { SelectStyles as Select } from "./styles/SelectStyles";
import { TagStyles as Tag } from "./styles/TagStyles";
import { ModalStyles as Modal } from "./styles/ModalStyles";
import { colorPalette } from "./styles/ColorPalette";

const fontList = {
	heading: "DM Sans, sans-serif",
	body: "DM Sans, sans-serif",
};

export const theme = extendTheme({
	styles: {
		global: {
			"html, body": {
				fontFamily: "DM sans, sans-serif",
				color: "dark",
				bg: "light",
			},
		},
	},
	colors: colorPalette,
	fonts: fontList,
	components: {
		Heading,
		Button,
		Input,
		Textarea,
		Select,
		Tag,
		Modal,
	},
});
