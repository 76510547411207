import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	Text,
	HStack,
	Tag,
	VStack,
	Heading,
	Box,
	Button,
	Wrap,
} from "@chakra-ui/react";
import { ScoredCandidateType } from "../types/CandidateType";
import { supabase } from "../supabase";
import {
	giveCandidateBgColor,
	giveCandidateBorderColor,
	giveCandidateTextColor,
} from "./CandidatePreview";

interface CandidateModalProps {
	isOpen: boolean;
	onClose: () => void;
	candidate: ScoredCandidateType;
}

function CandidateModal({ isOpen, onClose, candidate }: CandidateModalProps) {
	const candidateScore = candidate.scores?.[0]?.score || 0;
	const giveScore = (score: number) => {
		if (score >= 4) {
			return (
				<Tag>
					⭐️ All star {candidateScore}
					<Text as="span" opacity={0.6}>
						/5
					</Text>
				</Tag>
			);
		} else if (score >= 3) {
			return (
				<Tag variant="green">
					✅ Good fit {candidateScore}
					<Text as="span" opacity={0.6}>
						/5
					</Text>
				</Tag>
			);
		} else {
			return (
				<Tag variant="red">
					🚫 Not a fit {candidateScore}
					<Text as="span" opacity={0.6}>
						/5
					</Text>
				</Tag>
			);
		}
	};

	const handleViewResume = async () => {
		if (!candidate.resume_key) return;
		const { data } = await supabase.storage
			.from("resumes")
			.createSignedUrl(candidate.resume_key, 3600);

		if (data) {
			console.log(data.signedUrl);
			window.open(data.signedUrl, "_blank");
		}
		console.log("view resume");
	};
	return (
		<Modal isOpen={isOpen} onClose={onClose} size="xl">
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>{candidate.name}</ModalHeader>
				<ModalCloseButton />
				<ModalBody pb={6}>
					<VStack align="flex-start" spacing={4}>
						<Wrap justify="space-between" w="full">
							<HStack>
								{giveScore(candidateScore)}
								{candidate.edu_lvl && (
									<Tag variant="gray">{candidate.edu_lvl}</Tag>
								)}
								{candidate.xp_yrs && (
									<Tag variant="gray">
										{candidate.xp_yrs} years of experience
									</Tag>
								)}
							</HStack>
							{candidate.resume_key && (
								<Button
									size="xs"
									variant="gray"
									onClick={() => handleViewResume()}>
									View resume
								</Button>
							)}
						</Wrap>
						{candidate.scores?.[0]?.analysis && (
							<VStack
								align="flex-start"
								border="2px solid"
								bg={giveCandidateBgColor(candidateScore)}
								borderColor={giveCandidateBorderColor(candidateScore)}
								p={4}
								borderRadius="lg"
								color={giveCandidateTextColor(candidateScore)}
								w="full">
								<Heading size="md">AI Audit</Heading>

								<Text>{candidate.scores?.[0]?.analysis}</Text>
							</VStack>
						)}

						<VStack
							align="flex-start"
							bg="dark5"
							p={4}
							borderRadius="lg"
							w="full">
							<Heading size="md">Contact</Heading>
							<Box>
								<Text>
									<b>Location:</b> {candidate.location}
								</Text>
								<Text>
									<b>Phone:</b> {candidate.phone}
								</Text>
								<Text>
									<b>Email:</b> {candidate.email}
								</Text>
							</Box>
						</VStack>
						<VStack
							align="flex-start"
							bg="dark5"
							p={4}
							borderRadius="lg"
							w="full">
							<Heading size="md">Education</Heading>
							<Box>
								<Text fontWeight="bold">{candidate.edu_lvl}</Text>
								{candidate.education?.map((edu, i) => (
									<Text key={i}>{edu}</Text>
								))}
							</Box>
						</VStack>
						<VStack
							align="flex-start"
							bg="dark5"
							p={4}
							borderRadius="lg"
							w="full">
							<Heading size="md">Skills & Certifications</Heading>
							<Wrap>
								{candidate.skills?.map((skill, i) => (
									<Tag variant="gray" key={i}>
										{skill}
									</Tag>
								))}
							</Wrap>
							<Wrap>
								{candidate.certifications?.map((cert, i) => (
									<Tag variant="gray" key={i}>
										{cert}
									</Tag>
								))}
							</Wrap>
						</VStack>
						<VStack
							align="flex-start"
							bg="dark5"
							p={4}
							borderRadius="lg"
							w="full">
							<Heading size="md">Experience</Heading>
							{candidate.experience?.map((exp, i) => (
								<Text key={i}>{exp}</Text>
							))}
						</VStack>
					</VStack>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
}

export default CandidateModal;
