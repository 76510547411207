import { createContext, useContext, useEffect, useState } from "react";
import { CompanyType } from "../types/CompanyType";
import { CompanySVC } from "../services/CompanyServices";

type CompanyContextType = {
	company: CompanyType | undefined;
	userCompanies: CompanyType[] | undefined;
	refetchCompany: () => void;
	switchCompany: (id: string) => void;
};

export const CompanyContext = createContext<CompanyContextType>({
	company: undefined,
	userCompanies: undefined,
	refetchCompany: () => null,
	switchCompany: () => null,
});

export const CompanyProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	/** ––– How this works –––
	 * - user logs in (from user provider)
	 * - get all companies of the user
	 * - open the last-viewed company (stored in local storage)
	 * - if no company, open the first company
	 * - if user navigates to a company, open that company (refetch)
	 */

	const [hasFetched, setHasFetched] = useState<boolean>(false);

	const [userCompanies, setUserCompanies] = useState<CompanyType[]>();
	const [viewingCompany, setViewingCompany] = useState<CompanyType>();

	// if no company navigate

	// set the viewing company to the last viewed company
	useEffect(() => {
		const lastViewedCompany = localStorage.getItem("viewingCompany");
		if (lastViewedCompany && !viewingCompany) {
			setViewingCompany(JSON.parse(lastViewedCompany));
		} else if (userCompanies && !viewingCompany) {
			setViewingCompany(userCompanies[0]);
		}
	}, [viewingCompany]);

	useEffect(() => {
		const fetchCompany = async () => {
			const res = await CompanySVC.getByUser();
			setUserCompanies(res);
			setHasFetched(true);
		};
		if (!hasFetched) fetchCompany();
	}, [hasFetched]);

	useEffect(() => {
		if (userCompanies && !viewingCompany) {
			setViewingCompany(userCompanies[0]);
		}
	}, [userCompanies, viewingCompany]);

	const switchCompany = (id: string) => {
		const company = userCompanies?.find((c) => c.id === id);
		if (company) {
			localStorage.setItem("viewingCompany", JSON.stringify(company));
			setViewingCompany(company);
		}
	};

	const refetch = () => {
		setHasFetched(false);
	};

	return (
		<CompanyContext.Provider
			value={{
				company: viewingCompany,
				userCompanies,
				refetchCompany: refetch,
				switchCompany,
			}}>
			{children}
		</CompanyContext.Provider>
	);
};

export const useCompany = () => useContext(CompanyContext);
