import {
	VStack,
	Heading,
	Text,
	Image,
	Container,
	Link,
	HStack,
	Button,
	Box,
} from "@chakra-ui/react";
import jobCompleteIcon from "../assets/job-complete.png";
import { useEffect, useState } from "react";
import FileDropzone from "../components/FileDropzone";
import { DropzoneOptions } from "react-dropzone";
import { useNavigate, useParams } from "react-router";
import { AtsJobType } from "../types/AtsJobType";
import { ResumeSVC } from "../services/ResumeServices";
import { useResumeProcessing } from "../providers/ResumeProcessingProvider";
import { useJobs } from "../providers/JobsProvider";

export default function UploadCandidatesPage() {
	const navigate = useNavigate();
	const { queueResumes } = useResumeProcessing();
	const { jobId } = useParams();
	const { getOneJob } = useJobs();
	const [error, setError] = useState<string>("");
	const [uploading, setUploading] = useState<boolean>(false);
	const [files, setFiles] = useState<File[]>();
	const [job, setJob] = useState<AtsJobType>();

	const dropzoneOptions: DropzoneOptions = {
		accept: {
			"application/pdf": [],
			"application/doc": [],
			"application/docx": [],
		},
	};

	useEffect(() => {
		if (!jobId || job) return;
		const jobToSet = getOneJob(jobId);
		if (jobToSet) {
			setJob(jobToSet);
		}
	}, [jobId, job, getOneJob]);

	useEffect(() => {
		if (files && files.length > 0) {
			console.log(files[0]);
		}
	}, [files]);

	const handleFileUpload = (newFiles: File[]) => {
		// Merge the new files with the existing files
		setFiles(newFiles);
	};

	const upload = async () => {
		setUploading(true);
		if (!files) {
			setError("Please select a file to upload");
			setUploading(false);
			return;
		}

		// upload resumes
		if (!job || !job.id) {
			setError("Job not found");
			setUploading(false);
			return;
		}
		const results = await ResumeSVC.upload(files, job.id);
		if (results) {
			queueResumes(results);
		}
		console.log(results);
		navigate(`/job/${jobId}`);
		setUploading(false);
	};

	return (
		<>
			<VStack pb={40}>
				<Container maxW="4xl">
					<VStack align="flex-start" spacing={8}>
						<VStack align="flex-start">
							<Link
								onClick={() => navigate(-1)}
								color="primary"
								fontWeight="bold">
								← Go back
							</Link>
							<Heading size="2xl">Upload your resumes for the job.</Heading>
						</VStack>
						<VStack borderRadius="lg" spacing={8} bg="white" p={4} w="full">
							<VStack>
								<Image src={jobCompleteIcon} alt="job description" w={12} />
								<Heading size="lg" textAlign="center">
									{job ? job.name : "Job"} candidates
								</Heading>
								<Text color="dark60" textAlign="center">
									Upload the resumes of all your candidates for the job.
								</Text>
							</VStack>

							<FileDropzone
								name="resumes"
								many
								options={dropzoneOptions}
								onFilesChange={handleFileUpload}
							/>

							<HStack
								w="full"
								pt={4}
								justify="space-between"
								borderTop="2px solid"
								borderColor="light">
								<Text color="red">{error}</Text>
								<HStack>
									<Button variant="gray" onClick={() => navigate("/home")}>
										Return home
									</Button>
									<Button onClick={upload} isLoading={uploading}>
										Analyze resumes
									</Button>
								</HStack>
							</HStack>
						</VStack>
						<VStack w="full" align="flex-start">
							{files && files?.length > 0 && (
								<>
									<Text color="dark60" fontWeight="bold">
										{files?.length} {files?.length === 1 ? "file" : "files"}{" "}
										ready to upload
									</Text>
									{files?.map((file) => (
										<Box
											w="full"
											p={4}
											bg="primary10"
											borderRadius="lg"
											key={file.name}>
											<Text color="primary" fontWeight="bold">
												{file.name}
											</Text>
										</Box>
									))}
								</>
							)}
						</VStack>
					</VStack>
				</Container>
			</VStack>
		</>
	);
}
