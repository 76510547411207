import {
	VStack,
	Container,
	Heading,
	Link,
	HStack,
	Box,
	Text,
	Image,
	Checkbox,
	CheckboxGroup,
	Button,
} from "@chakra-ui/react";
import {
	AtsApplication,
	AtsApplicationStatus,
} from "@unified-api/typescript-sdk/dist/sdk/models/shared";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { ApplicationStatusType } from "../../types/CandidateType";
import axios from "axios";
import { useCompany } from "../../providers/CompanyProvider";
import { useJobs } from "../../providers/JobsProvider";
import { AtsJobType } from "../../types/AtsJobType";
import LoadingScreen from "../../components/LoadingScreen";
import existingAppsIcon from "../../assets/candidate.png";
import { QueueSVC } from "../../services/QueueServices";
import { CandidateSVC } from "../../services/CandidateServices";

// Add this type definition
type CountType = { status: string; total: number };

const DEMO_APPLICATIONS = [
	{
		id: "1",
		status: AtsApplicationStatus.New,
		createdAt: new Date("2024-01-01"),
		updatedAt: new Date("2024-01-01"),
		source: "LinkedIn",
	},
	{
		id: "2",
		status: AtsApplicationStatus.New,
		createdAt: new Date("2024-01-01"),
		updatedAt: new Date("2024-01-01"),
		source: "LinkedIn",
	},
	{
		id: "3",
		status: AtsApplicationStatus.New,
		createdAt: new Date("2024-01-01"),
		updatedAt: new Date("2024-01-01"),
		source: "LinkedIn",
	},
];

export default function ConfigureSourcingPage() {
	const navigate = useNavigate();
	const { company } = useCompany();
	const { currentJob: job } = useJobs();
	const [loading, setLoading] = useState<boolean>(false);
	const [actionLoading, setActionLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>("");
	const [applications, setApplications] = useState<AtsApplication[]>([]);
	const [selectedApplications, setSelectedApplications] = useState<
		AtsApplication[]
	>([]);
	const [counts, setCounts] = useState<CountType[]>([]);

	const [selectedStatuses, setSelectedStatuses] = useState<
		ApplicationStatusType[]
	>([]);
	const [allStatuses, setAllStatuses] = useState<ApplicationStatusType[]>([]);

	// DEMO: set applications
	useEffect(() => {
		setApplications(DEMO_APPLICATIONS);
		setCounts([{ status: "NEW", total: 3 }]);
		setAllStatuses(["NEW"]);
	}, []);

	// log selected applications
	useEffect(() => {
		console.log(selectedApplications);
	}, [selectedApplications]);

	// get all existing applications
	useEffect(() => {
		// get applications
		const fetchApplications = async (job: AtsJobType) => {
			setLoading(true);
			if (company && job?.id) {
				try {
					const res = await axios.post("/api/ats/get-all-applications", {
						companyId: company.id,
						exJobId: job.ex_id,
					});
					if (res.data.redirect) {
						window.location.href = `/job/${job.id}`;
					} else {
						console.log(res.data);
						setApplications(res.data.applications);
						setCounts(res.data.counts);

						// Filter statuses that have more than 0 applications
						const filteredStatuses = res.data.counts
							.filter((count: CountType) => count.total > 0)
							.map((count: CountType) => count.status as ApplicationStatusType);
						setAllStatuses(filteredStatuses);
					}
				} catch (error) {
					console.error("Error fetching applications:", error);
					setError("Failed to fetch applications");
				} finally {
					setLoading(false);
					setActionLoading(false);
				}
			}
		};

		if (!loading && company && applications.length === 0) {
			if (job) {
				// fetchApplications(job);
			}
		}
	}, [loading, company, applications]);

	// Handle Select All
	const handleSelectAll = () => {
		if (selectedStatuses.length === allStatuses.length) {
			setSelectedStatuses([]);
			setSelectedApplications([]);
		} else {
			setSelectedStatuses(allStatuses);
			setSelectedApplications(applications);
		}
	};

	// Handle individual checkbox change
	const handleCheckboxChange = (status: ApplicationStatusType) => {
		if (selectedStatuses.includes(status)) {
			setSelectedStatuses((prev) => prev.filter((s) => s !== status));
			setSelectedApplications((prev) =>
				prev.filter((app) => app.status !== status)
			);
		} else {
			setSelectedStatuses((prev) => [...prev, status]);
			setSelectedApplications((prev) => [
				...prev,
				...applications.filter((app) => app.status === status),
			]);
		}
	};

	const handleStartSorting = async () => {
		setActionLoading(true);
		if (selectedApplications.length === 0) {
			setActionLoading(false);
			navigate(`/job/${job?.id}`);
		} else if (company && job?.id) {
			// DEMO: go straight to job page
			const DEMOres = await CandidateSVC.DEMOCreateCandidates(job.id);
			if (DEMOres.status === 200) {
				navigate(`/job/${job.id}`);
				setActionLoading(false);
			} else {
				setError("Failed to create demo candidates");
				setActionLoading(false);
			}

			// REAL: queue applications
			// add ex_id to conditionals
			// const res = await QueueSVC.queueExistingApps(
			// 	company.id,
			// 	job.id,
			// 	job.ex_id,
			// 	selectedApplications
			// );

			// if (res !== null) {
			// 	navigate(`/job/${job.id}`);
			// 	setActionLoading(false);
			// } else {
			// 	setError("Failed to queue applications");
			// 	setActionLoading(false);
			// }
		}
	};

	if (loading) {
		return <LoadingScreen text="Checking for applications" />;
	} else
		return (
			<VStack>
				<Container maxW="4xl">
					<VStack align="flex-start" spacing={8}>
						<VStack align="flex-start">
							<Link
								onClick={() => navigate(-1)}
								color="primary"
								fontWeight="bold"
								pb={4}>
								← Go back
							</Link>
							<Heading size="2xl">Which applications should we sort?</Heading>
						</VStack>
						<VStack
							borderRadius="lg"
							spacing={4}
							bg="white"
							p={4}
							w="full"
							align="flex-start">
							<HStack gap={4}>
								<Image src={existingAppsIcon} alt="job description" w={12} />
								<Box>
									<Heading size="lg">
										Existing applications{" "}
										<Heading size="lg" as="span" color="primary"></Heading>
									</Heading>
									<Text color="dark60">
										Select existing applications that you want to sort for this
										job.
									</Text>
								</Box>
							</HStack>

							<VStack align="flex-start" spacing={2} p={4}>
								<Text color="dark60">
									{applications.length} applications found
								</Text>
								{/* Select All Checkbox */}
								<Checkbox
									isChecked={selectedStatuses.length === allStatuses.length}
									size="lg"
									onChange={handleSelectAll}>
									Select All
								</Checkbox>

								{/* Render checkboxes for each status */}
								<CheckboxGroup>
									{allStatuses.map((status) => {
										const count =
											counts.find((c) => c.status === status)?.total || 0;
										return (
											<Checkbox
												key={status}
												size="lg"
												isChecked={selectedStatuses.includes(status)}
												onChange={() => handleCheckboxChange(status)}>
												<b>{status}</b> | {count} applications
											</Checkbox>
										);
									})}
								</CheckboxGroup>
							</VStack>
							<HStack
								w="full"
								pt={4}
								justify="space-between"
								borderTop="2px solid"
								borderColor="light">
								<Text color="red">{error}</Text>
								<Button onClick={handleStartSorting} isLoading={actionLoading}>
									Start sorting
								</Button>
							</HStack>
						</VStack>
					</VStack>
				</Container>
			</VStack>
		);
}
