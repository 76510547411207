import { IconProps, Icon } from "@chakra-ui/react";

type MoreIconProps = {
	color?: string;
	heightInRem?: number;
} & IconProps;

export const MoreIcon = ({
	color = "dark",
	heightInRem = 1.25,
}: MoreIconProps) => {
	return (
		<Icon
			fill={color}
			width={`${heightInRem}rem`}
			height={`${heightInRem}rem`}
			viewBox="0 0 40 40">
			<path d="M19.9963 33.3333C19.3229 33.3333 18.7478 33.0936 18.2708 32.6142C17.7939 32.1347 17.5554 31.5583 17.5554 30.885C17.5554 30.2117 17.7951 29.6365 18.2746 29.1596C18.7543 28.6829 19.3307 28.4446 20.0038 28.4446C20.6771 28.4446 21.2522 28.6843 21.7292 29.1638C22.2061 29.6432 22.4446 30.2196 22.4446 30.8929C22.4446 31.5663 22.2049 32.1413 21.7254 32.6179C21.2457 33.0949 20.6693 33.3333 19.9963 33.3333ZM19.9963 22.4446C19.3229 22.4446 18.7478 22.2049 18.2708 21.7254C17.7939 21.2457 17.5554 20.6693 17.5554 19.9963C17.5554 19.3229 17.7951 18.7478 18.2746 18.2708C18.7543 17.7939 19.3307 17.5554 20.0038 17.5554C20.6771 17.5554 21.2522 17.7951 21.7292 18.2746C22.2061 18.7543 22.4446 19.3307 22.4446 20.0038C22.4446 20.6771 22.2049 21.2522 21.7254 21.7292C21.2457 22.2061 20.6693 22.4446 19.9963 22.4446ZM19.9963 11.5554C19.3229 11.5554 18.7478 11.3157 18.2708 10.8363C17.7939 10.3568 17.5554 9.78042 17.5554 9.10709C17.5554 8.43376 17.7951 7.85875 18.2746 7.38209C18.7543 6.90514 19.3307 6.66667 20.0038 6.66667C20.6771 6.66667 21.2522 6.90639 21.7292 7.38584C22.2061 7.86528 22.4446 8.44167 22.4446 9.11501C22.4446 9.78834 22.2049 10.3635 21.7254 10.8404C21.2457 11.3171 20.6693 11.5554 19.9963 11.5554Z" />
		</Icon>
	);
};
