import { Outlet, useNavigate } from "react-router";
import Nav from "./components/Nav";
import { useEffect, useState } from "react";
import { supabase } from "./supabase";
import { Session } from "@supabase/supabase-js";
import Auth from "./pages/AuthPage";
import { useResumeProcessing } from "./providers/ResumeProcessingProvider";
import LoadingScreen from "./components/LoadingScreen";
import { Box } from "@chakra-ui/react";

/** ––– App component –––
 *
 * @returns The whole app with data context
 *
 * @description This is the main component of the app. It contains the main layout and the router outlet.
 */

function App() {
	const navigate = useNavigate();
	const { filesToProcess } = useResumeProcessing();
	const [session, setSession] = useState<Session | null>(null);
	const [fetched, setFetched] = useState<boolean>(false);

	useEffect(() => {
		supabase.auth.getSession().then(({ data: { session } }) => {
			setSession(session);
			setFetched(true);
		});

		supabase.auth.onAuthStateChange((_event, session) => {
			setSession(session);
			setFetched(true);
		});
	}, []);

	// redirect from root to home
	useEffect(() => {
		if (window.location.pathname === "/") {
			navigate("/home");
		}
		// eslint-disable-next-line
	}, []);

	// Warn user before leaving the page if files are still being processed
	useEffect(() => {
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			if (filesToProcess.length > 0) {
				event.returnValue =
					"Files are still being processed. Are you sure you want to leave?";
			}
		};

		window.addEventListener("beforeunload", handleBeforeUnload);

		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, [filesToProcess]);

	if (!fetched) {
		return <LoadingScreen />;
	}
	if (!session) {
		return <Auth />;
	}

	return (
		<Box minH="100vh">
			<Nav />
			<Outlet />
		</Box>
	);
}

export default App;
