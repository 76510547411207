import {
	VStack,
	HStack,
	Heading,
	Text,
	Flex,
	Textarea,
	Button,
	Box,
	Spinner,
} from "@chakra-ui/react";
import FileDropzone from "./FileDropzone";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { DropzoneOptions } from "react-dropzone";
import { JobSVC } from "../services/JobServices";
import { AtsJobType } from "../types/AtsJobType";
import { SorterSVC } from "../services/SorterServices";

export default function UploadJobDescription({ job }: { job: AtsJobType }) {
	const navigate = useNavigate();
	const [description, setDescription] = useState<string>("");
	const [file, setFile] = useState<File[]>();
	const [error, setError] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);

	const dropzoneOptions: DropzoneOptions = {
		accept: {
			"application/pdf": [],
			"application/doc": [],
			"application/docx": [],
		},
		maxFiles: 1,
	};

	const handleUploadNewFile = () => {
		setFile(undefined);
	};

	const handleContinue = async () => {
		if (!description && !file) {
			setError("Please provide a job description or upload a file.");
			return;
		}
		setLoading(true);
		let genSorter;
		if (!job.id) return;
		if (file && file.length > 0) {
			const JDpathName = await JobSVC.uploadJD(file[0]);
			genSorter = await SorterSVC.generateDraft(
				job.id,
				description,
				JDpathName
			);
		} else {
			genSorter = await SorterSVC.generateDraft(job.id, description);
		}

		if (genSorter) {
			navigate(`/add-sorter/${job.id}/edit-details/${genSorter.id}`);
			setLoading(false);
		} else {
			setError("Failed to generate. Please try again.");
			setLoading(false);
		}
	};

	useEffect(() => {
		if (file && file.length > 0) {
			console.log(file[0]);
		}
	}, [file]);

	if (!loading) {
		return (
			<>
				<HStack w="full">
					<Flex flex={1}>
						<Textarea
							placeholder="Type job description ..."
							rows={10}
							value={description}
							onChange={(e) => setDescription(e.target.value)}
						/>
					</Flex>
					<Flex flex={1}>
						{file ? (
							<VStack w="full">
								<Box w="full" p={4} bg="primary10" borderRadius="lg">
									<Text color="primary" fontWeight="bold">
										{file[0]?.name}
									</Text>
								</Box>
								<Button w="full" variant="gray" onClick={handleUploadNewFile}>
									Upload a different file
								</Button>
							</VStack>
						) : (
							<FileDropzone
								name="job description"
								options={dropzoneOptions}
								onFilesChange={(files) => setFile(files)}
							/>
						)}
					</Flex>
				</HStack>
				<HStack
					w="full"
					pt={4}
					justify="space-between"
					borderTop="2px solid"
					borderColor="light">
					<Text color="red">{error}</Text>
					<Button onClick={handleContinue}>Continue</Button>
				</HStack>
			</>
		);
	} else {
		return (
			<>
				<VStack
					w="full"
					justify="center"
					py={20}
					spacing={8}
					borderRadius="lg"
					bg="primary10">
					<Spinner color="primary" size="xl" thickness="4px" />
					<Heading color="primary" size="lg">
						Analyzing, please wait...
					</Heading>
				</VStack>
			</>
		);
	}
}
