import { Button, HStack, Heading, Tag, Text, VStack } from "@chakra-ui/react";
import { ScoredCandidateType } from "../types/CandidateType";
import CandidateModal from "./CandidateModal";
import { useState } from "react";

const allStar: number = 4;
const goodFit: number = 3;

export const giveCandidateBorderColor = (score: number) => {
	if (score >= allStar) {
		return "primary";
	} else if (score >= goodFit) {
		return "green";
	} else {
		return "red20";
	}
};

export const giveCandidateBgColor = (score: number) => {
	if (score >= allStar) {
		return "primary10";
	} else if (score >= goodFit) {
		return "green5";
	} else {
		return "red5";
	}
};

export const giveCandidateTextColor = (score: number) => {
	if (score >= allStar) {
		return "deepBlue";
	} else if (score >= goodFit) {
		return "deepGreen";
	} else {
		return "deepRed";
	}
};

function CandidatePreview({ candidate }: { candidate: ScoredCandidateType }) {
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const candidateScore = candidate.scores?.[0]?.score || 0; // Define constant

	const giveScore = (score: number) => {
		if (score >= allStar) {
			return (
				<Tag>
					⭐️ All star {candidate.scores?.[0]?.score}
					<Text as="span" opacity={0.6}>
						/5
					</Text>
				</Tag>
			);
		} else if (score >= goodFit) {
			return (
				<Tag variant="green">
					✅ Good fit {candidate.scores?.[0]?.score}
					<Text as="span" opacity={0.6}>
						/5
					</Text>
				</Tag>
			);
		} else {
			return (
				<Tag variant="red">
					🚫 Not a fit {candidate.scores?.[0]?.score}
					<Text as="span" opacity={0.6}>
						/5
					</Text>
				</Tag>
			);
		}
	};

	return (
		<>
			<HStack
				onClick={() => setModalOpen(true)}
				justify="space-between"
				w="full"
				p={3}
				border="2px solid"
				borderRadius="lg"
				borderColor={giveCandidateBorderColor(candidateScore)}
				_hover={{
					bg: giveCandidateBgColor(candidateScore),
					cursor: "pointer",
				}}
				color={giveCandidateTextColor(candidateScore)}>
				<HStack>
					<VStack align="flex-start" spacing={0}>
						<Heading size="md">{candidate.name}</Heading>
						<Text size="sm">{candidate.location}</Text>
					</VStack>
				</HStack>
				<VStack align="flex-end" spacing={1}>
					{giveScore(candidateScore)}
					<HStack spacing={1}>
						<Button size="xs" variant="gray" onClick={() => setModalOpen(true)}>
							View profile
						</Button>
					</HStack>
				</VStack>
			</HStack>
			<CandidateModal
				isOpen={modalOpen}
				onClose={() => setModalOpen(false)}
				candidate={candidate}
			/>
		</>
	);
}

export default CandidatePreview;
