import { useEffect, useState } from "react";
import {
	Box,
	Heading,
	Text,
	VStack,
	Button,
	Image,
	Select,
	HStack,
	Flex,
	Container,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/wordmark.png";
import screeningIcon from "../../assets/candidate.png";
const statusOptions = {
	NEW: "New",
	REVIEWING: "Reviewing",
	SCREENING: "Screening",
	SUBMITTED: "Submitted",
	FIRST_INTERVIEW: "First Interview",
	SECOND_INTERVIEW: "Second Interview",
	THIRD_INTERVIEW: "Third Interview",
	BACKGROUND_CHECK: "Background Check",
	OFFERED: "Offered",
	ACCEPTED: "Accepted",
	HIRED: "Hired",
	REJECTED: "Rejected",
	DECLINED: "Declined",
	WITHDRAWN: "Withdrawn",
};

export default function ConfigureScreeningPage() {
	const navigate = useNavigate();

	// const [applicationStatuses, setApplicationStatuses] = useState<
	// 	ApplicationStatusType[]
	// >([]);
	const [selectedStatus, setSelectedStatus] = useState<{
		[key: string]: string | null;
	}>({
		allStar: "SUBMITTED",
		goodFits: "SUBMITTED",
		badFits: "REJECTED",
	});

	useEffect(() => {
		const fetchApplicationStatuses = async () => {
			// try {
			// 	const statuses = await ApplicationStatusSVC.getAll();
			// 	setApplicationStatuses(statuses);
			// } catch (error) {
			// 	console.error("Failed to fetch application statuses:", error);
			// }
		};

		fetchApplicationStatuses();
	}, []);

	const handleStatusSelect = (category: string, statusId: string) => {
		setSelectedStatus((prev) => ({ ...prev, [category]: statusId }));
	};

	const handleSaveConfiguration = async () => {
		try {
			// await ScreeningConfigSVC.save({
			// 	selectedStatus,
			// 	scoreThresholds
			// });
			// Navigate or show success message
		} catch (error) {
			console.error("Failed to save screening configuration:", error);
			// Show error message
		}
	};

	const handleContinue = () => {
		// Add logic for continuing to the next step
		// For now, we'll just navigate back to the dashboard
		navigate("/home");
	};

	return (
		<>
			<VStack mt={20}>
				<Container maxW="3xl">
					<VStack spacing={8} align="stretch" pb={12}>
						<Image src={logo} alt="logo" width={180} />
						<Heading as="h1" size="2xl">
							How should TalentSort sync with your ATS?
						</Heading>
					</VStack>
					<VStack
						spacing={4}
						align="stretch"
						borderRadius="lg"
						bg="white"
						p={4}
						w="full">
						<HStack gap={4}>
							<Image src={screeningIcon} alt="job description" w={12} />
							<Box>
								<Heading size="lg">
									Screening thresholds
									<Heading size="lg" as="span" color="primary"></Heading>
								</Heading>
								<Text color="dark60">
									Map score thresholds to your ATS statuses to automate your
									screening process.
								</Text>
							</Box>
						</HStack>

						{["allStar", "goodFits", "badFits"].map((category) => (
							<Box
								key={category}
								border="4px solid"
								borderColor={
									category === "allStar"
										? "primary20"
										: category === "goodFits"
										? "green20"
										: "red20"
								}
								borderRadius="lg"
								p={3}>
								<HStack>
									<Flex flex={1} direction="column">
										<Heading size="md">
											{category === "allStar"
												? "⭐️ All star"
												: category === "goodFits"
												? "✅ Good fit"
												: "🚫 Not a fit"}
										</Heading>
										<Text opacity={0.6}>
											{category === "allStar"
												? "Candidates who score 4 or 5 stars"
												: category === "goodFits"
												? "Candidates who score 3 or 4 stars"
												: "Candidates who score 1 or 2 stars"}
										</Text>
									</Flex>

									<Flex flex={1} direction="column">
										<Text fontSize="sm" fontWeight="bold" color="deepBlue60">
											ATS Status
										</Text>
										<Select
											placeholder="Select status"
											value={selectedStatus[category] || ""}
											onChange={(e) =>
												handleStatusSelect(category, e.target.value)
											}>
											{Object.entries(statusOptions).map(([key, value]) => (
												<option key={key} value={key}>
													{value}
												</option>
											))}
										</Select>
									</Flex>
								</HStack>
							</Box>
						))}
						<HStack
							w="full"
							pt={4}
							justify="flex-end"
							borderTop="2px solid"
							borderColor="light">
							<Button
								onClick={handleContinue}
								colorScheme="blue"
								alignSelf="flex-end">
								Continue
							</Button>
						</HStack>
					</VStack>
				</Container>
			</VStack>
		</>
	);
}
