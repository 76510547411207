import axios from "axios";
import { supabase } from "../supabase";
import { CandidateType, ScoredCandidateType } from "../types/CandidateType";

async function getByJobId(jobId: string): Promise<CandidateType[]> {
	const { data } = await supabase
		.from("candidates")
		.select()
		.eq("job_id", jobId);
	if (!data) return [];
	return data;
}

async function getScoredCandidates(
	jobId: string
): Promise<ScoredCandidateType[]> {
	const { data, error } = await supabase
		.from("candidates")
		.select(
			`
			*,
			scores:scores (
				created_at,
				score,
				analysis
			)
		`
		)
		.eq("job_id", jobId);

	if (error) {
		console.error("Error fetching scored candidates:", error);
		return [];
	}

	console.log("data", data);
	return data;
}

async function DEMOCreateCandidates(jobId: string) {
	const res = await axios.post("/api/demo-create-candidates", { jobId });
	return res;
}

export const CandidateSVC = {
	getByJobId,
	getScoredCandidates,
	DEMOCreateCandidates,
};
