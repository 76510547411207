import {
	Text,
	SimpleGrid,
	Textarea,
	VStack,
	HStack,
	Button,
	Tag,
	Wrap,
	TagCloseButton,
	Input,
	IconButton,
} from "@chakra-ui/react";
import { SortedJobType } from "../types/SorterType";
import { useState } from "react";
import { AddIcon } from "../assets/icons/AddIcon";
import { SorterSVC } from "../services/SorterServices";
import { useNavigate } from "react-router";

export default function EditSorterForm({
	sorter,
	navigatePath,
}: {
	sorter: SortedJobType;
	navigatePath?: string;
}) {
	const navigate = useNavigate();
	const [roleDetails, setRoleDetails] = useState<string>(
		sorter.role_details ? sorter.role_details : ""
	);
	const [sortCriteria, setSortCriteria] = useState<string>(
		sorter.sort_criteria ? sorter.sort_criteria : ""
	);
	const [relatedTitles, setRelatedTitles] = useState<string[]>(
		sorter.related_titles ? sorter.related_titles : []
	);
	const [newTitle, setNewTitle] = useState<string>("");
	const [isInputVisible, setIsInputVisible] = useState<boolean>(false);
	const [error, setError] = useState<string>("");

	const handleAddTitle = () => {
		if (newTitle.trim()) {
			setRelatedTitles([...relatedTitles, newTitle.trim()]);
			setNewTitle("");
			setIsInputVisible(false);
		}
	};

	const handleRemoveTitle = (titleToRemove: string) => {
		setRelatedTitles(relatedTitles.filter((title) => title !== titleToRemove));
	};

	const handleSave = async () => {
		// save sorter details
		// navigate to given page
		const updatedSorter = {
			role_details: roleDetails,
			sort_criteria: sortCriteria,
			related_titles: relatedTitles,
		};

		const res = await SorterSVC.updateOne(sorter.id, updatedSorter);
		if (res) {
			if (navigatePath) return navigate(navigatePath);
			else return navigate(`/job/${sorter.job_id}`);
		} else {
			console.error("Failed to update sorter:", res);
			setError("Failed to update");
		}
	};

	return (
		<>
			<SimpleGrid columns={2} spacing={2} py={1} w="full">
				<VStack align="flex-start" w="full">
					<Text fontWeight="bold">Role details</Text>
					<Textarea
						value={roleDetails}
						onChange={(e) => setRoleDetails(e.target.value)}
						placeholder="Type role definition"
						rows={10}
					/>
				</VStack>
				<VStack align="flex-start" w="full">
					<Text fontWeight="bold">Sort criteria</Text>
					<Textarea
						value={sortCriteria}
						onChange={(e) => setSortCriteria(e.target.value)}
						placeholder="Type role criteria"
						rows={10}
					/>
				</VStack>
			</SimpleGrid>
			<VStack align="flex-start" w="full">
				<Text fontWeight="bold">Relevant roles to consider</Text>
				<Wrap spacing={1} align="center">
					{relatedTitles.map((title) => (
						<Tag key={title} colorScheme="primary">
							{title}
							<TagCloseButton onClick={() => handleRemoveTitle(title)} />
						</Tag>
					))}
					{!isInputVisible && (
						<Button
							variant="gray"
							size="xs"
							leftIcon={<AddIcon heightInRem={1.2} />}
							onClick={() => setIsInputVisible(true)}>
							Add a title
						</Button>
					)}
				</Wrap>
				{isInputVisible && (
					<HStack>
						<Input
							size="sm"
							minW={240}
							value={newTitle}
							onChange={(e) => setNewTitle(e.target.value)}
							placeholder="Type new title"
						/>
						<IconButton
							icon={<AddIcon heightInRem={1.2} color="white" />}
							aria-label="Add title"
							size="sm"
							onClick={handleAddTitle}
						/>
					</HStack>
				)}
				<HStack
					w="full"
					pt={4}
					justify="space-between"
					borderTop="2px solid"
					borderColor="light">
					<Text color="red">{error}</Text>
					<Button onClick={handleSave}>Save</Button>
				</HStack>
			</VStack>
		</>
	);
}
