import axios from "axios";
import { supabase } from "../supabase";
import { SortedJobType, SorterType } from "../types/SorterType";

const generateDraft = async (
	jobId: string,
	input: string,
	filePath?: string
) => {
	const data = {
		jobId,
		text: input,
		filePath,
	};
	try {
		const res = await axios.post("/api/generate-sorter", data);
		return res.data as SorterType;
	} catch (error) {
		console.error("Failed to generate draft:", error);
		return null;
	}
};

const updateOne = async (id: string, sorter: Partial<SorterType>) => {
	const { data, error } = await supabase
		.from("sorters")
		.update(sorter)
		.eq("id", id)
		.select();
	if (error) {
		console.error("Failed to update sorter:", error);
		return null;
	}
	return data;
};

const getOne = async (id: string) => {
	const { data, error } = await supabase
		.from("sorters")
		.select(
			`id, job_id, sort_criteria, role_details, related_titles, created_at, updated_at,
			ats_jobs (name, status)`
		)
		.eq("id", id)
		.single();
	const sortedData = data as unknown as SortedJobType;
	if (error) {
		console.error("Failed to get sorter:", error);
		return null;
	}
	return sortedData as SortedJobType;
};

const getMany = async (compId: string) => {
	const { data, error } = await supabase
		.from("sorters")
		.select("*")
		.eq("company_id", compId);
	if (error) {
		console.error("Failed to get sorters:", error);
		return null;
	}
	return data as SorterType[];
};

export const SorterSVC = { generateDraft, updateOne, getOne, getMany };
