import { IconProps, Icon } from "@chakra-ui/react";

type MenuIconProps = {
	color?: string;
	heightInRem?: number;
} & IconProps;

export const MenuIcon = ({
	color = "dark",
	heightInRem = 1.25,
}: MenuIconProps) => {
	return (
		<Icon
			fill={color}
			width={`${heightInRem}rem`}
			height={`${heightInRem}rem`}
			viewBox="0 0 40 40">
			<path d="M6.66667 30C6.19444 30 5.79861 29.8403 5.47917 29.5208C5.15972 29.2014 5 28.8056 5 28.3333C5 27.8611 5.15972 27.4653 5.47917 27.1458C5.79861 26.8264 6.19444 26.6667 6.66667 26.6667H33.3333C33.8056 26.6667 34.2014 26.8264 34.5208 27.1458C34.8403 27.4653 35 27.8611 35 28.3333C35 28.8056 34.8403 29.2014 34.5208 29.5208C34.2014 29.8403 33.8056 30 33.3333 30H6.66667ZM6.66667 21.6667C6.19444 21.6667 5.79861 21.5069 5.47917 21.1875C5.15972 20.8681 5 20.4722 5 20C5 19.5278 5.15972 19.1319 5.47917 18.8125C5.79861 18.4931 6.19444 18.3333 6.66667 18.3333H33.3333C33.8056 18.3333 34.2014 18.4931 34.5208 18.8125C34.8403 19.1319 35 19.5278 35 20C35 20.4722 34.8403 20.8681 34.5208 21.1875C34.2014 21.5069 33.8056 21.6667 33.3333 21.6667H6.66667ZM6.66667 13.3333C6.19444 13.3333 5.79861 13.1736 5.47917 12.8542C5.15972 12.5347 5 12.1389 5 11.6667C5 11.1944 5.15972 10.7986 5.47917 10.4792C5.79861 10.1597 6.19444 10 6.66667 10H33.3333C33.8056 10 34.2014 10.1597 34.5208 10.4792C34.8403 10.7986 35 11.1944 35 11.6667C35 12.1389 34.8403 12.5347 34.5208 12.8542C34.2014 13.1736 33.8056 13.3333 33.3333 13.3333H6.66667Z" />
		</Icon>
	);
};
