import { ComponentStyleConfig } from "@chakra-ui/react";

export const ModalStyles: ComponentStyleConfig = {
	// style object for base or default style
	baseStyle: {
		overlay: {
			bg: "dark40", //change the background
			backdropFilter: "blur(8px)",
		},
		dialog: {
			borderRadius: "lg",
			bg: `light`,
			backdropFilter: "blur(16px)",
		},
		header: {
			fontSize: "2xl",
			fontWeight: 800,
			fontFamily: "heading",
			bg: "transparent",
		},
		closeButton: {
			size: "xl",
			marginTop: "0.6rem",
			marginRight: "0.4rem",
		},
	},
};
