import axios from "axios";
import { Resume } from "../providers/ResumeProcessingProvider";
import { supabase } from "../supabase";
import { v4 as uuidv4 } from "uuid";

// LOCAL FNs
const addToQueue = async (resumeKey: string, jobId: string) => {
	const { data, error } = await supabase
		.from("resume_queue")
		.insert([{ resume_key: resumeKey, job_id: jobId }])
		.select();

	if (error) {
		console.error("Failed to add to queue", error);
		throw error;
	}
	return data[0];
};

// EXPORTS
const upload = async (files: File[], jobId: string) => {
	const uploadPromises = files.map(async (file) => {
		const uniqueFileName = uuidv4() + "-" + file.name;
		try {
			return await supabase.storage
				.from("resumes")
				.upload(uniqueFileName, file);
		} catch (error) {
			return { error };
		}
	});

	const results: any = await Promise.all(uploadPromises);

	const errors = results.filter(
		(result: any): result is { error: Error } => result.error !== null
	);
	if (errors.length > 0) {
		throw new Error("One or more files failed to upload.");
	}

	const queuePromises = results.map((result: any) => {
		if (result.data && result.data.path) {
			return addToQueue(result.data.path, jobId);
		} else return Promise.resolve(null);
	});

	const resumeQueue = await Promise.all(queuePromises);

	return resumeQueue;
};

const process = async (resume: Resume) => {
	const res = await axios.post("/api/process-resume", { resume });
	return res.data;
};

const update = async (resume: Resume) => {
	const { data, error } = await supabase
		.from("resume_queue")
		.update(resume)
		.eq("id", resume.id)
		.select();
	if (error) {
		console.error("Failed to update resume", error);
		throw error;
	}
	return data ? data[0] : null;
};

const deleteFile = async (id: string) => {
	const { data, error } = await supabase
		.from("resume_queue")
		.select()
		.eq("job_id", id);

	if (error) {
		console.error("Failed to get resume keys:", error);
		return false;
	}

	for (const record of data) {
		const { error } = await supabase.storage
			.from("resumes")
			.remove([record.resume_key]);

		if (error) {
			console.error("Failed to delete resume file:", error);
			return false;
		}
		console.log("␡ File removed: " + record.resume_key);
	}
	return true;
};

export const ResumeSVC = {
	upload,
	process,
	deleteFile,
	update,
};
