import { Container, Heading, Spinner, VStack } from "@chakra-ui/react";

function LoadingScreen({ text }: { text?: string }) {
	return (
		<VStack>
			<Container maxW="4xl" height="600px">
				<VStack
					h="full"
					flexGrow={1}
					align="center"
					justify="center"
					spacing={8}>
					<Spinner color="primary" size="xl" thickness="4px" />
					<Heading color="primary" size="lg">
						{text ? text : "Loading..."}
					</Heading>
				</VStack>
			</Container>
		</VStack>
	);
}

export default LoadingScreen;
