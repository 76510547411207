import { Icon, IconProps } from "@chakra-ui/react";

type UploadIconProps = {
	color?: string;
	heightInRem?: number;
} & IconProps;

export const UploadIcon = ({
	color = "dark",
	heightInRem = 1.25,
}: UploadIconProps) => {
	return (
		<Icon
			width={`${heightInRem}rem`}
			height={`${heightInRem}rem`}
			viewBox="0 0 40 40"
			fill={color}>
			<path d="M10 33.3333C9.08335 33.3333 8.29863 33.0069 7.64585 32.3542C6.99308 31.7014 6.66669 30.9167 6.66669 30V26.6667C6.66669 26.1944 6.82641 25.7986 7.14585 25.4792C7.4653 25.1597 7.86113 25 8.33335 25C8.80558 25 9.20141 25.1597 9.52085 25.4792C9.8403 25.7986 10 26.1944 10 26.6667V30H30V26.6667C30 26.1944 30.1597 25.7986 30.4792 25.4792C30.7986 25.1597 31.1945 25 31.6667 25C32.1389 25 32.5347 25.1597 32.8542 25.4792C33.1736 25.7986 33.3334 26.1944 33.3334 26.6667V30C33.3334 30.9167 33.007 31.7014 32.3542 32.3542C31.7014 33.0069 30.9167 33.3333 30 33.3333H10ZM18.3334 13.0833L15.2084 16.2083C14.875 16.5417 14.4792 16.7014 14.0209 16.6875C13.5625 16.6736 13.1667 16.5 12.8334 16.1667C12.5278 15.8333 12.3681 15.4444 12.3542 15C12.3403 14.5556 12.5 14.1667 12.8334 13.8333L18.8334 7.83333C19 7.66667 19.1806 7.54861 19.375 7.47917C19.5695 7.40972 19.7778 7.375 20 7.375C20.2222 7.375 20.4306 7.40972 20.625 7.47917C20.8195 7.54861 21 7.66667 21.1667 7.83333L27.1667 13.8333C27.5 14.1667 27.6597 14.5556 27.6459 15C27.632 15.4444 27.4722 15.8333 27.1667 16.1667C26.8334 16.5 26.4375 16.6736 25.9792 16.6875C25.5209 16.7014 25.125 16.5417 24.7917 16.2083L21.6667 13.0833V25C21.6667 25.4722 21.507 25.8681 21.1875 26.1875C20.8681 26.5069 20.4722 26.6667 20 26.6667C19.5278 26.6667 19.132 26.5069 18.8125 26.1875C18.4931 25.8681 18.3334 25.4722 18.3334 25V13.0833Z" />
		</Icon>
	);
};
