export const colorPalette = {
	primary: "#0092FB",
	primary80: "rgba(0, 146, 251, 0.8)",
	primary60: "rgba(0, 146, 251, 0.6)",
	primary40: "rgba(0, 146, 251, 0.4)",
	primary20: "rgba(0, 146, 251, 0.2)",
	primary10: "rgba(0, 146, 251, 0.1)",
	primary5: "rgba(0, 146, 251, 0.05)",
	light: "#F1F4F9",
	light80: "#F1F4F9CC",
	light60: "#F1F4F999",
	light40: "#F1F4F966",
	light20: "#F1F4F933",
	dark: "#003E6A",
	dark60: "rgba(0, 62, 106, 0.6)",
	dark40: "rgba(0, 62, 106, 0.4)",
	dark20: "rgba(0, 62, 106, 0.2)",
	dark10: "rgba(0, 62, 106, 0.1)",
	dark5: "rgba(0, 62, 106, 0.05)",
	deepBlue: "#2B4D74",
	deepBlue80: "rgba(43, 77, 116, 0.8)",
	deepBlue60: "rgba(43, 77, 116, 0.6)",
	deepBlue40: "rgba(43, 77, 116, 0.4)",
	deepBlue20: "rgba(43, 77, 116, 0.2)",
	deepBlue10: "rgba(43, 77, 116, 0.1)",
	blueGray: "#D7DEE7",
	green: "#34A853",
	green20: "rgba(52, 168, 83, 0.2)",
	green5: "rgba(52, 168, 83, 0.05)",
	deepGreen: "#135023",
	red: "#EA4335",
	red20: "rgba(234, 67, 53, 0.2)",
	red5: "rgba(234, 67, 53, 0.05)",
	deepRed: "#7C0A02",
	gradientBg:
		"linear-gradient(0deg, rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.75)), linear-gradient(131.69deg, #0092FB 34.93%, #7CDCF1 110.81%)",
};
