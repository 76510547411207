import { Heading, Spinner, VStack } from "@chakra-ui/react";
import { useJobs } from "../../providers/JobsProvider";
import { useEffect, useState } from "react";

export default function SyncJobsPage() {
	const { initiateSync } = useJobs();
	const [synced, setSynced] = useState(false);

	useEffect(() => {
		const res = initiateSync();
	}, [initiateSync]);

	return (
		<VStack spacing={4} justify="center" bg="white" p={4} h="100vh" w="full">
			<Heading size="lg">syncing jobs from ATS ...</Heading>
			<Spinner color="primary" size="xl" thickness="6px" />
		</VStack>
	);
}
