import {
	VStack,
	Container,
	Box,
	Heading,
	HStack,
	Text,
	Image,
	Link,
	Button,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { SorterSVC } from "../../services/SorterServices";
import { SortedJobType } from "../../types/SorterType";
import jobCriteriaIcon from "../../assets/job-complete.png";
import EditSorterForm from "../../components/EditSorterForm";

export default function EditSorterDetailsPage() {
	const navigate = useNavigate();
	const { sorterId } = useParams();
	const [sorter, setSorter] = useState<SortedJobType>();

	useEffect(() => {
		const fetchSorter = async () => {
			if (sorterId && !sorter) {
				const sorterToSet = await SorterSVC.getOne(sorterId);
				if (sorterToSet) {
					setSorter(sorterToSet);
					console.log(sorterToSet);
				}
			}
		};
		fetchSorter();
	}, [sorterId, sorter]);

	return (
		<VStack pb={40}>
			<Container maxW="4xl">
				<VStack align="flex-start" spacing={8}>
					<VStack align="flex-start">
						<Link
							onClick={() => navigate(-1)}
							color="primary"
							fontWeight="bold"
							pb={4}>
							← Go back
						</Link>
						<Heading size="2xl">Let’s help the AI sort the candidates.</Heading>
					</VStack>
					<VStack
						borderRadius="lg"
						spacing={4}
						bg="white"
						p={4}
						w="full"
						align="flex-start">
						<HStack gap={4}>
							<Image src={jobCriteriaIcon} alt="job description" w={12} />
							<Box>
								<Heading size="lg">
									Guidelines for{" "}
									<Heading size="lg" as="span" color="primary">
										{sorter?.ats_jobs.name}
									</Heading>
								</Heading>
								<Text color="dark60">
									This is context used by the AI to screen candidates for the
									job.
								</Text>
							</Box>
						</HStack>
						{sorter && (
							<EditSorterForm
								sorter={sorter}
								navigatePath={`/add-sorter/${sorter.job_id}/configure-sourcing`}
							/>
						)}
					</VStack>
				</VStack>
			</Container>
		</VStack>
	);
}
