import { ComponentStyleConfig } from "@chakra-ui/react";

export const TagStyles: ComponentStyleConfig = {
	parts: ["container", "label"],
	baseStyle: {
		container: {
			width: "max-content",
			height: "24px",
			cursor: "pointer",
		},
		label: {
			fontWeight: 800,
			fontSize: "sm",
		},
	},
	sizes: {},
	variants: {
		primary: {
			container: {
				bg: "primary",
				color: "white",
			},
		},
		gray: {
			container: {
				bg: "dark10",
				color: "dark",
			},
		},
		green: {
			container: {
				bg: "green",
				color: "white",
			},
		},
		red: {
			container: {
				bg: "red20",
				color: "red",
			},
		},
	},
	defaultProps: {
		variant: "primary",
		size: "md",
	},
};
