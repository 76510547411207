import { useEffect, useState } from "react";
import { supabase } from "../supabase";
import {
	Input,
	FormControl,
	FormLabel,
	Heading,
	Text,
	VStack,
	Button,
	HStack,
	Image,
	Flex,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router";
import waitingRoom from "../assets/waiting-room.png";
import wordmark from "../assets/wordmark.png";

export default function Auth() {
	const navigate = useNavigate();
	const { authState } = useParams();
	const [newUser, setNewUser] = useState(false);
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	useEffect(() => {
		if (authState === "signup") {
			setNewUser(true);
		}
	}, [authState]);

	const handleSignup = async (event: any) => {
		event.preventDefault();

		setLoading(true);
		const { error } = await supabase.auth.signUp({ email, password });

		if (error) {
			alert(error.message);
		} else {
			// create a company for the user
			navigate("/add-company");
		}
		setLoading(false);
	};

	const handleLogin = async (event: any) => {
		event.preventDefault();

		setLoading(true);
		const { error } = await supabase.auth.signInWithPassword({
			email,
			password,
		});

		if (error) {
			alert(error.message);
		} else {
			navigate("/home");
		}
		setLoading(false);
	};

	const giveForm = () => {
		if (newUser) {
			return (
				<VStack align="flex-start" flex={1} p={8} spacing={4}>
					<Heading>Sign up</Heading>
					<Text>Enter your email & password to sign up</Text>
					<FormControl>
						<FormLabel>Email</FormLabel>
						<Input
							type="email"
							placeholder="Your email"
							value={email}
							required={true}
							onChange={(e) => setEmail(e.target.value)}
						/>{" "}
					</FormControl>{" "}
					<FormControl>
						{" "}
						<FormLabel>New Password</FormLabel>{" "}
						<Input
							type="password"
							placeholder="Your password"
							value={password}
							required={true}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</FormControl>
					<HStack>
						<Button
							isLoading={loading}
							loadingText="..."
							onClick={handleSignup}>
							Sign up
						</Button>
						<Button variant="gray" onClick={() => setNewUser(false)}>
							Go to sign in
						</Button>
					</HStack>
				</VStack>
			);
		} else {
			return (
				<VStack align="flex-start" flex={1} p={8} spacing={4}>
					<Heading>Login</Heading>
					<Text>Enter your email & password to login</Text>
					<FormControl>
						<FormLabel>Email</FormLabel>
						<Input
							type="email"
							placeholder="Your email"
							value={email}
							required={true}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</FormControl>
					<FormControl>
						<FormLabel>Password</FormLabel>
						<Input
							type="password"
							placeholder="Your password"
							value={password}
							required={true}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</FormControl>
					<HStack>
						<Button isLoading={loading} loadingText="..." onClick={handleLogin}>
							Sign in
						</Button>
						<Button variant="gray" onClick={() => setNewUser(true)}>
							Go to sign up
						</Button>
					</HStack>
				</VStack>
			);
		}
	};

	return (
		<VStack minH="100vh" justify="space-between" py={4}>
			<HStack
				w={{ base: "sm", md: "4xl" }}
				bg="white"
				borderRadius="xl"
				align="stretch"
				minH={540}
				mt={20}>
				<Flex
					display={{ base: "none", md: "flex" }}
					flex={1}
					justify="left"
					align="flex-start"
					bgImg={waitingRoom}
					bgSize="cover"
					bgPos="center"
					p={4}
					borderLeftRadius="xl">
					<Flex borderRadius="lg" bg="white" p={4}>
						<Image src={wordmark} alt="logo" w={32} />
					</Flex>
				</Flex>
				{giveForm()}
			</HStack>
			<Text color="dark60" fontSize="sm">
				© 2024 TalentSort Inc. All Rights Reserved.
			</Text>
		</VStack>
	);
}
