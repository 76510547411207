import { Session, User } from "@supabase/supabase-js";
import { createContext, useContext, useEffect, useState } from "react";
import { supabase } from "../supabase";

type AuthSessionContextType = {
	session: Session | undefined;
	user: User | undefined;
	refetchSession: () => Promise<Session | null | undefined>;
};

export const AuthSessionContext = createContext<AuthSessionContextType>({
	session: undefined,
	user: undefined,
	refetchSession: () => Promise.resolve(undefined),
});

export const AuthSessionProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const [session, setSession] = useState<Session>();
	const [user, setUser] = useState<User>();

	useEffect(() => {
		const { data } = supabase.auth.onAuthStateChange(async (event, session) => {
			const user = session?.user;
			if (session) {
				setSession(session);
				setUser(user);
			}
		});

		return () => {
			data?.subscription.unsubscribe();
		};
	}, []);

	const refetchSession = async () => {
		console.log("Refetching session");
		try {
			const { data, error } = await supabase.auth.getSession();
			if (data.session) {
				setSession(data.session);
				setUser(session?.user);
			}
			if (error) {
				console.error("Error refetching session:", error);
				throw error;
			}
			return session;
		} catch (error) {
			console.error("Error refetching session:", error);
			return null;
		}
	};

	return (
		<AuthSessionContext.Provider value={{ session, user, refetchSession }}>
			{children}
		</AuthSessionContext.Provider>
	);
};

export const useAuthSession = () => {
	const context = useContext(AuthSessionContext);
	if (!context) {
		throw new Error(
			"useAuthSession must be used within an AuthSessionProvider"
		);
	}
	return context;
};
