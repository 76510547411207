import { Icon, IconProps } from "@chakra-ui/react";

type AddIconProps = {
	color?: string;
	heightInRem?: number;
} & IconProps;

export const AddIcon = ({
	color = "dark",
	heightInRem = 1.25,
}: AddIconProps) => {
	return (
		<Icon
			width={`${heightInRem}rem`}
			height={`${heightInRem}rem`}
			viewBox="0 0 40 40"
			fill={color}>
			<path d="M30.0002 21.6667H21.6668V30C21.6668 30.9167 20.9168 31.6667 20.0002 31.6667C19.0835 31.6667 18.3335 30.9167 18.3335 30V21.6667H10.0002C9.0835 21.6667 8.3335 20.9167 8.3335 20C8.3335 19.0834 9.0835 18.3334 10.0002 18.3334H18.3335V10C18.3335 9.08337 19.0835 8.33337 20.0002 8.33337C20.9168 8.33337 21.6668 9.08337 21.6668 10V18.3334H30.0002C30.9168 18.3334 31.6668 19.0834 31.6668 20C31.6668 20.9167 30.9168 21.6667 30.0002 21.6667Z" />
		</Icon>
	);
};
