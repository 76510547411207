import { Button, Container, HStack, Heading, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router";
import CompanyPreview from "../components/CompanyPreview";
import { CompanyType } from "../types/CompanyType";
import { useEffect, useState } from "react";
import { CompanySVC } from "../services/CompanyServices";

export default function AccountPage() {
	const navigate = useNavigate();
	const [companies, setCompanies] = useState<CompanyType[]>([]);

	// get the user

	// get the companies from the user
	useEffect(() => {
		const fetchCompanies = async () => {
			const companiesOfUser = await CompanySVC.getByUser();
			if (!companiesOfUser) return;
			setCompanies(companiesOfUser);
		};
		fetchCompanies();
	}, []);

	const giveCompanies = () => {
		if (!companies || companies.length === 0) {
			return (
				<HStack
					borderRadius="lg"
					border="1px solid"
					borderColor="blueGray"
					color="dark40"
					spacing={4}
					p={4}
					w="full"
					justify="center"
					textAlign="center">
					<Heading size="md">
						<i>no companies added yet...</i>
					</Heading>
				</HStack>
			);
		} else
			return companies.map((company) => (
				<CompanyPreview key={company.id} company={company} />
			));
	};

	return (
		<VStack>
			<Container maxW="4xl">
				<VStack align="flex-start" spacing={8}>
					<HStack justify="space-between" w="full">
						<Heading size="2xl">Account</Heading>
						<Button onClick={() => navigate("/settings")}>Edit settings</Button>
					</HStack>
					<VStack
						borderRadius="lg"
						bg="white"
						p={4}
						w="full"
						align="flex-start">
						<HStack justify="space-between" w="full" mb={4}>
							<Heading size="lg">Companies</Heading>
							<Button size="sm" onClick={() => navigate("/add-company")}>
								Add a company
							</Button>
						</HStack>
						{giveCompanies()}
					</VStack>
				</VStack>
			</Container>
		</VStack>
	);
}
