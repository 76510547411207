import { IconProps, Icon } from "@chakra-ui/react";

type JobIconProps = {
	color?: string;
	heightInRem?: number;
} & IconProps;

export const JobIcon = ({
	color = "dark",
	heightInRem = 1.25,
}: JobIconProps) => {
	return (
		<Icon
			fill={color}
			width={`${heightInRem}rem`}
			height={`${heightInRem}rem`}
			viewBox="0 0 40 40">
			<path d="M6 5.9375C6 5.42364 6.20413 4.93083 6.56748 4.56748C6.93083 4.20413 7.42364 4 7.9375 4H27.3125C27.8264 4 28.3192 4.20413 28.6825 4.56748C29.0459 4.93083 29.25 5.42364 29.25 5.9375V18.5312C29.25 18.7882 29.1479 19.0346 28.9663 19.2163C28.7846 19.3979 28.5382 19.5 28.2812 19.5C28.0243 19.5 27.7779 19.3979 27.5962 19.2163C27.4146 19.0346 27.3125 18.7882 27.3125 18.5312V5.9375H7.9375V33.0625H13.75V28.2188C13.75 27.9618 13.8521 27.7154 14.0337 27.5337C14.2154 27.3521 14.4618 27.25 14.7188 27.25H17.625V35H7.9375C7.42364 35 6.93083 34.7959 6.56748 34.4325C6.20413 34.0692 6 33.5764 6 33.0625V5.9375Z" />
			<path d="M10.8438 7.875C10.5868 7.875 10.3404 7.97706 10.1588 8.15874C9.97708 8.34042 9.87501 8.58682 9.87501 8.84375V10.7812C9.87501 11.0382 9.97708 11.2846 10.1588 11.4663C10.3404 11.6479 10.5868 11.75 10.8438 11.75H12.7813C13.0382 11.75 13.2846 11.6479 13.4663 11.4663C13.6479 11.2846 13.75 11.0382 13.75 10.7812V8.84375C13.75 8.58682 13.6479 8.34042 13.4663 8.15874C13.2846 7.97706 13.0382 7.875 12.7813 7.875H10.8438Z" />
			<path d="M15.6875 8.84375C15.6875 8.58682 15.7896 8.34042 15.9713 8.15874C16.1529 7.97706 16.3993 7.875 16.6563 7.875H18.5938C18.8507 7.875 19.0971 7.97706 19.2788 8.15874C19.4605 8.34042 19.5625 8.58682 19.5625 8.84375V10.7812C19.5625 11.0382 19.4605 11.2846 19.2788 11.4663C19.0971 11.6479 18.8507 11.75 18.5938 11.75H16.6563C16.3993 11.75 16.1529 11.6479 15.9713 11.4663C15.7896 11.2846 15.6875 11.0382 15.6875 10.7812V8.84375Z" />
			<path d="M22.4688 7.875C22.2118 7.875 21.9654 7.97706 21.7838 8.15874C21.6021 8.34042 21.5 8.58682 21.5 8.84375V10.7812C21.5 11.0382 21.6021 11.2846 21.7838 11.4663C21.9654 11.6479 22.2118 11.75 22.4688 11.75H24.4063C24.6632 11.75 24.9096 11.6479 25.0913 11.4663C25.273 11.2846 25.375 11.0382 25.375 10.7812V8.84375C25.375 8.58682 25.273 8.34042 25.0913 8.15874C24.9096 7.97706 24.6632 7.875 24.4063 7.875H22.4688Z" />
			<path d="M9.87501 14.6562C9.87501 14.3993 9.97708 14.1529 10.1588 13.9712C10.3404 13.7896 10.5868 13.6875 10.8438 13.6875H12.7813C13.0382 13.6875 13.2846 13.7896 13.4663 13.9712C13.6479 14.1529 13.75 14.3993 13.75 14.6562V16.5938C13.75 16.8507 13.6479 17.0971 13.4663 17.2788C13.2846 17.4604 13.0382 17.5625 12.7813 17.5625H10.8438C10.5868 17.5625 10.3404 17.4604 10.1588 17.2788C9.97708 17.0971 9.87501 16.8507 9.87501 16.5938V14.6562Z" />
			<path d="M16.6563 13.6875C16.3993 13.6875 16.1529 13.7896 15.9713 13.9712C15.7896 14.1529 15.6875 14.3993 15.6875 14.6562V16.5938C15.6875 16.8507 15.7896 17.0971 15.9713 17.2788C16.1529 17.4604 16.3993 17.5625 16.6563 17.5625H18.5938C18.8507 17.5625 19.0971 17.4604 19.2788 17.2788C19.4605 17.0971 19.5625 16.8507 19.5625 16.5938V14.6562C19.5625 14.3993 19.4605 14.1529 19.2788 13.9712C19.0971 13.7896 18.8507 13.6875 18.5938 13.6875H16.6563Z" />
			<path d="M21.5 14.6562C21.5 14.3993 21.6021 14.1529 21.7838 13.9712C21.9654 13.7896 22.2118 13.6875 22.4688 13.6875H24.4063C24.6632 13.6875 24.9096 13.7896 25.0913 13.9712C25.273 14.1529 25.375 14.3993 25.375 14.6562V16.5938C25.375 16.8507 25.273 17.0971 25.0913 17.2788C24.9096 17.4604 24.6632 17.5625 24.4063 17.5625H22.4688C22.2118 17.5625 21.9654 17.4604 21.7838 17.2788C21.6021 17.0971 21.5 16.8507 21.5 16.5938V14.6562Z" />
			<path d="M10.8438 19.5C10.5868 19.5 10.3404 19.6021 10.1588 19.7837C9.97708 19.9654 9.87501 20.2118 9.87501 20.4688V22.4062C9.87501 22.6632 9.97708 22.9096 10.1588 23.0913C10.3404 23.2729 10.5868 23.375 10.8438 23.375H12.7813C13.0382 23.375 13.2846 23.2729 13.4663 23.0913C13.6479 22.9096 13.75 22.6632 13.75 22.4062V20.4688C13.75 20.2118 13.6479 19.9654 13.4663 19.7837C13.2846 19.6021 13.0382 19.5 12.7813 19.5H10.8438Z" />
			<path d="M15.6875 20.4688C15.6875 20.2118 15.7896 19.9654 15.9713 19.7837C16.1529 19.6021 16.3993 19.5 16.6563 19.5H18.5938C18.8507 19.5 19.0971 19.6021 19.2788 19.7837C19.4605 19.9654 19.5625 20.2118 19.5625 20.4688V22.4062C19.5625 22.6632 19.4605 22.9096 19.2788 23.0913C19.0971 23.2729 18.8507 23.375 18.5938 23.375H16.6563C16.3993 23.375 16.1529 23.2729 15.9713 23.0913C15.7896 22.9096 15.6875 22.6632 15.6875 22.4062V20.4688Z" />
			<path d="M19.3964 33.819C19.3964 35 20.5774 35 20.5774 35H32.3869C32.3869 35 33.5679 35 33.5679 33.819C33.5679 32.6381 32.3869 29.0952 26.4821 29.0952C20.5774 29.0952 19.3964 32.6381 19.3964 33.819Z" />
			<path d="M28.9873 26.8766C28.3229 27.541 27.4218 27.9143 26.4821 27.9143C25.5425 27.9143 24.6414 27.541 23.977 26.8766C23.3126 26.2122 22.9393 25.3111 22.9393 24.3714C22.9393 23.4318 23.3126 22.5307 23.977 21.8663C24.6414 21.2018 25.5425 20.8286 26.4821 20.8286C27.4218 20.8286 28.3229 21.2018 28.9873 21.8663C29.6517 22.5307 30.025 23.4318 30.025 24.3714C30.025 25.3111 29.6517 26.2122 28.9873 26.8766Z" />
		</Icon>
	);
};
