import { ComponentStyleConfig } from "@chakra-ui/react";

export const ButtonStyles: ComponentStyleConfig = {
	// style object for base or default style
	baseStyle: {
		_disabled: {
			opacity: 1,
			cursor: "auto",
			boxShadow: "none",
		},
	},
	// styles for different sizes ("sm", "md", "lg")
	sizes: {
		xs: {
			padding: "0px 8px 0px 8px",
			fontSize: "xs",
			height: "24px",
		},
		sm: {
			padding: "0px 12px 0px 12px",
			height: "30px",
			fontSize: "sm",
		},
	},
	// styles for different visual variants ("outline", "solid")
	variants: {
		primary: {
			bg: "primary",
			color: "white",
			border: "3px solid",
			borderColor: "transparent",
			_hover: {
				bg: "primary80",
				borderColor: "primary",
			},
			_loading: {
				bg: "primary80",
				opacity: 1,
				borderColor: "primary",
				_hover: {
					bg: "primary80",
					opacity: 1,
					borderColor: "primary",
				},
			},
		},
		gray: {
			bg: "blueGray",
			color: "dark",
			border: "3px solid",
			borderColor: "blueGray",
			_hover: {
				bg: "light",
			},
		},
		red: {
			bg: "red",
			color: "white",
			border: "3px solid",
			borderColor: "transparent",
			_hover: {
				bg: "red20",
				borderColor: "red",
			},
			_loading: {
				bg: "red20",
				opacity: 1,
				borderColor: "red",
				_hover: {
					bg: "red20",
					opacity: 1,
					borderColor: "red",
				},
			},
		},
		text: {
			bg: "transparent",
			color: "dark60",
			border: "none",
			_hover: {
				bg: "primary5",
				color: "primary",
				textDecoration: "underline",
			},
		},
		icon: {
			bg: "transparent",
			_hover: {
				bg: "dark5",
			},
		},
		company: {
			bg: "light",
			color: "dark",
			border: "none",
			_hover: {
				bg: "blueGray",
			},
		},
	},
	defaultProps: {
		size: "md",
		variant: "primary",
	},
};
