import { ComponentStyleConfig } from "@chakra-ui/react";

export const TextareaStyles: ComponentStyleConfig = {
	baseStyle: {},
	sizes: {},
	variants: {
		outline: {
			border: "2px solid",
			borderColor: "blueGray",
			_hover: {
				borderColor: "primary60",
			},
			_focusVisible: {
				borderColor: "primary",
				boxShadow: "0 0 0 1px #0092FB",
			},
		},
	},
	defaultProps: {
		variant: "outline",
	},
};
