import { Heading, HStack, Button, Text, Tag } from "@chakra-ui/react";
import { AtsJobType } from "../types/AtsJobType";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";

function JobPreview({ job }: { job: AtsJobType }) {
	const navigate = useNavigate();
	const [hasSorter, setHasSorter] = useState<boolean>(false);

	useEffect(() => {
		if (job.sorters && job.sorters.length > 0) {
			setHasSorter(true);
		}
	}, [job]);

	const openSortedJob = () => {
		navigate(`/job/${job.id}`);
	};

	if (hasSorter) {
		return (
			<HStack
				as="button"
				onClick={openSortedJob}
				bg="white"
				p={3}
				borderRadius="md"
				border="4px solid"
				borderColor="transparent"
				_hover={{ borderColor: "dark10" }}
				w="full"
				justify="space-between">
				<HStack spacing={6}>
					<Heading size="sm">{job.name}</Heading>
					<Tag size="sm" variant="gray">
						{job.status}
					</Tag>
				</HStack>
				<HStack>
					<Text fontSize="sm" color="dark40">
						Click to view candidates
					</Text>
				</HStack>
			</HStack>
		);
	} else {
		return (
			<HStack
				bg="dark10"
				p={3}
				borderRadius="md"
				w="full"
				justify="space-between">
				<HStack spacing={6}>
					<Heading size="sm">{job.name}</Heading>
					<Tag size="sm" variant="gray">
						{job.status}
					</Tag>
				</HStack>
				<Button size="sm" onClick={() => navigate(`/add-sorter/${job.id}`)}>
					Sort candidates
				</Button>
			</HStack>
		);
	}
}

export default JobPreview;
