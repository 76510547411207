import { supabase } from "../supabase";

import { v4 as uuidv4 } from "uuid";
import { ResumeSVC } from "./ResumeServices";
import axios from "axios";
import { AtsJobType } from "../types/AtsJobType";

// local functions
async function deleteRelatedRecords(
	tableName: string,
	id: string
): Promise<boolean> {
	// remove resume files if tableName is "resume_queue"
	if (tableName === "resume_queue") {
		await ResumeSVC.deleteFile(id);
	}

	const { data, error } = await supabase
		.from(tableName)
		.delete()
		.match({ job_id: id });

	if (error) {
		console.error("Failed to delete related records:", error);
		return false;
	}

	console.log(`Deleted related records from ${tableName}:`, data);
	return true;
}

// exported functions

const syncFromAts = async (compId: string) => {
	try {
		const res = await axios.post("/api/ats/sync-jobs", { companyId: compId });
		return res;
	} catch (error) {
		console.error("Failed to sync jobs from ATS:", error);
		return null;
	}
};

const getAll = async (compId: string) => {
	const { data, error } = await supabase
		.from("ats_jobs")
		.select(
			`
			id,
			ex_id,
			name,
			description,
			status,
			addresses,
			employment_type,
			remote,
			departments,
			language_locale,
			number_of_openings,
			company_id,
			sorters (sort_criteria, role_details, related_titles)
			`
		)
		.eq("company_id", compId);
	if (error) {
		console.error("Failed to get jobs:", error);
		return null;
	}
	return data;
};

const deleteOne = async (id: string) => {
	const tables = ["candidates", "resume_queue"];
	for (const table of tables) {
		const success = await deleteRelatedRecords(table, id);
		if (!success) {
			throw new Error(`Failed to delete records in ${table} for job ID ${id}`);
		}
	}

	const { error } = await supabase.from("jobs").delete().eq("id", id);
	return error;
};

const uploadJD = async (file: File) => {
	const uniqueFileName = uuidv4() + "-" + file.name;
	const { data, error } = await supabase.storage
		.from("job-descriptions")
		.upload(uniqueFileName, file);

	if (error) {
		console.error("Failed to upload JD", error);
		throw error;
	}
	return data.path;
};

const create = async (job: any) => {
	try {
		// Create new job(s) in the database
		const { data, error } = await supabase
			.from("ats_jobs")
			.insert([job])
			.select();
		if (error) throw new Error(error.message);
		return data[0];
	} catch (error: any) {
		throw new Error(error.message);
	}
};

const createMany = async (jobs: any[]) => {
	try {
		// Create new job(s) in the database
		const { data, error } = await supabase.from("ats_jobs").insert(jobs);
		if (error) throw new Error(error.message);
		return data;
	} catch (error: any) {
		throw new Error(error.message);
	}
};

export const JobSVC = {
	syncFromAts,
	getAll,
	deleteOne,
	uploadJD,
	create,
};
