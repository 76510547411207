import { useDropzone, DropzoneOptions } from "react-dropzone";
import { Box, Button, Text, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { UploadIcon } from "../assets/icons/UploadIcon";

type FileDropzoneProps = {
	name: string;
	options: DropzoneOptions;
	onFilesChange: (files: File[]) => void;
	many?: boolean;
};

function FileDropzone({
	name,
	options,
	onFilesChange,
	many,
}: FileDropzoneProps) {
	const [files, setFiles] = useState<File[]>([]);
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		...options,
		onDrop: (acceptedFiles) => {
			// Append new files to the existing array
			const updatedFiles = [...files, ...acceptedFiles];
			setFiles(updatedFiles);
			// Notify the parent component
			onFilesChange(updatedFiles);
		},
	});

	return (
		<Box
			{...getRootProps()}
			p={2}
			border="2px dashed"
			borderColor={isDragActive ? "primary" : "blueGray"}
			_hover={{ borderColor: "primary" }}
			borderRadius="md"
			w="full"
			h={60}>
			<input {...getInputProps()} />
			<VStack
				spacing={4}
				textAlign="center"
				h="full"
				justify="center"
				borderRadius="md"
				bg="light">
				<Button leftIcon={<UploadIcon color="white" />}>Upload {name}</Button>
				<Box>
					{isDragActive ? (
						<Text color="primary">
							Release to upload {many ? "files" : "the file"}
						</Text>
					) : (
						<Text color="dark60">
							Or drop {many ? "files" : "the file"} here
						</Text>
					)}
					<Text color="dark60" fontSize="xs">
						supported formats: *.pdf, *.doc, *.docx
					</Text>
				</Box>
			</VStack>
		</Box>
	);
}

export default FileDropzone;
